import React from 'react';
import { Tooltip, VStack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';

const CustomCalendarEvent = ({ event }) => {
  //console.log(event);
  const formatTime = (date) => {
    return format(new Date(date), 'h:mm a');
  };

  const formatDate = (date) => {
    return format(new Date(date), 'MM/dd/yyyy h:mm a');
  }

  return (
    <Tooltip label={`${event.title}: ${formatDate(event.start)} to ${formatDate(event.end)}`}>
      <VStack
        align="stretch"
        spacing={0}
        height="100%"
        width="100%"
        p={0.5}
      >
        <Text
          fontWeight="bold"
          fontSize="xs"
          mb={0.5}
        >
          {event.title}
        </Text>
        <Text
          fontSize="2xs"
          color="white"
        >
          {formatTime(event.start)} - {formatTime(event.end)}
        </Text>
      </VStack>
    </Tooltip>
  );
};

export default CustomCalendarEvent;