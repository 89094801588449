import React from 'react';
import { Box, VStack, Text, Popover, PopoverTrigger, Button, useDisclosure, Tooltip, useColorModeValue } from '@chakra-ui/react';
import NewGoalEntryForm from '../forms/NewGoalEntryForm';
import { showCellWriting } from '../helpers/cells';

const StandardCell = ({ period, length, cell_width, itemData }) => {
  // Filter the forward_new_goal_entries based on the period
  //console.log("Item Data: ", itemData)
  const filteredEntries = React.useMemo(() => {
    if (!itemData.forward_new_goal_entries) return [];
    return itemData.forward_new_goal_entries.filter(entry => {
      const entryDate = new Date(entry.period_date);
      return entryDate >= period.fullStart && entryDate <= period.fullEnd;
    });
  }, [itemData.forward_new_goal_entries, period.start, period.end]);
  //console.log("Filtered Entries: ", filteredEntries);
  const {isOpen, onOpen, onClose} = useDisclosure();

  const goal_status = filteredEntries?.[0]?.goal_status || null;
  const goal_text = filteredEntries?.[0]?.goal_list?.[0] || null;

  const getGoalColor = (status, mode = 'light') => {
    if(mode === 'light') {
    if(status === 10) {
      return "green.500";
    }
    else if(status === 5) {
      return "yellow.500";
    }
    else if(status === -10) {
      return "red.500";
    }
    else if (status === -1) {
      return "gray.400";
    }
    else if(status === null) {
      return "gray.200";
    }
    else {
      return "gray.500";
    }
  }
  else {
    if(status === 10) {
      return "green.700";
    }
    else if(status === 5) {
      return "yellow.700";
    }
    else if(status === -10) {
      return "red.700";
    }
    else if (status === -1) {
      return "gray.600";
    }
    else if(status === null) {
      return "gray.700";
    }
    else {
      return "gray.800";
  }
  }
}
const cmode = useColorModeValue('light', 'dark');
  const cellbg=getGoalColor(goal_status,cmode);
  const isDisabled = false;

  const onToggle = () => {
    onOpen();
  }

  const showText = showCellWriting(goal_text, cell_width);

  return (

    <Popover
        isOpen={isOpen}
        onClose={onClose}
    >
      <PopoverTrigger>
      
    <Box bg={cellbg} border="1px solid" borderColor="gray.300" height="100%" minHeight="32px" p={0} cursor="pointer" onClick={() => onToggle()}>
    <Tooltip label={goal_text} aria-label="A tooltip">
      <VStack align="start" spacing={0}>
        {filteredEntries.map((entry, index) => {
          return (
          showText ? (<Text key={index} fontSize="xs" textAlign="center" width="100%">
            {goal_text}
          </Text>) : (<Text fontSize="xs" textAlign="center" width="100%">...</Text>))
        })}
      </VStack>
      </Tooltip>
    </Box>
    </PopoverTrigger>
    <NewGoalEntryForm 
    existingEntryId={filteredEntries?.[0]?.id}
    goalName={itemData.goal_name} 
    goalId={itemData.id}
    areaId={itemData.area_id}
    userId={itemData.user_id}
    startDate={period.fullStart} 
    endDate={period.fullEnd} 
    textValue={goal_text} 
    statusValue={goal_status}  
    isDisabled={isDisabled} onClose={onClose} />

    </Popover>
  );
};

export default StandardCell;