import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    VStack, Text, Center, Heading, Box, HStack, Button, IconButton, useColorModeValue,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, Divider, AccordionIcon, useToast, Menu, MenuButton, MenuList, MenuItem, useStyleConfig
  } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { DragDropContext } from '@hello-pangea/dnd';
import TaskContainerBox from '../boxes/TaskContainerBox.jsx';
import c from '../../../uc_constants/fwd_constants.js'
import { AiOutlineSetting } from 'react-icons/ai';
import { useTaskDnd } from '../../../data_hooks/dnd/useTaskDnd.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import AreaRemindersBox from '../../areas/boxes/AreaRemindersBox.jsx';

const PinnedTasks = ({session, location="home", embed=false, ...props}) => {
    const toast = useToast();
    const styles = useStyleConfig('Box', {variant: 'widgetBox'})
    const { state, dispatch } = useContext(ForwardContext);
    const {handlePinnedDragEnd} = useTaskDnd();
    const [isExpanded, setIsExpanded] = useState(true); // State to toggle visibility
    const accordionButtonRef = useRef(null);
    const { addTask } = useContext(FormContext);

  // Function to toggle accordion
  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
    // Trigger click on AccordionButton
    accordionButtonRef.current?.click();
  };

    const bgColor = useColorModeValue('forwardWhite.500','forwardBlue.800')
    const bdColor = useColorModeValue("forwardBlue.700","forwardGold.600");
  
    const [pinnedTasks, setPinnedTasks] = useState([]);

    const handleEmbedLink = () => {
      const linkText = c.forward_main_url+'/embed/pinnedtasks';
        navigator.clipboard.writeText(linkText)
          .then(() => {
            toast.closeAll(); // Close all existing toasts
            toast({
              title: 'Link copied to clipboard',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          })
          .catch((error) => {
            console.error('Failed to copy link to clipboard:', error);
            toast.closeAll(); // Close all existing toasts
            toast({
              title: 'Failed to copy link to clipboard',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          });
  
    }


    useEffect(() => {
      const updatedPinnedTasks = Object.values(state.tasks).reduce((accumulator, project) => {
        if (project.this_week && project.this_week.length > 0) {
          accumulator.push(...project.this_week.filter((task) => task.is_pinned === true));
        }
        return accumulator;
      }, []);
      updatedPinnedTasks.sort((a, b) => a.pin_order_id - b.pin_order_id);
      setPinnedTasks(updatedPinnedTasks);
    }, [state.tasks]);

  return (
    <Box 
     width="100%"
     maxWidth="1000px"
     __css={styles}
     p={0}
    {...props}>
    <Accordion width="100%" mt="0px" defaultIndex={[0]} allowMultiple {...props}>
      <AccordionItem mt={0} borderWidth="0px !important">
        {/* Accordion Header */}
        <HStack as="h2" px={4} pt={4} pb={2}  mt={0}   justifyContent="space-between">
          <HStack flex="10" justifyContent="space-between">
            <HStack px={1}>
            {c.main_icons.pin}
            <Text fontWeight='bold'>Pinned Tasks</Text>
            </HStack>
            <Button className="forwardButton" m={0} size="sm" onClick={() => addTask({is_pinned:true})} variant="solid" colorScheme="forwardOrange">+ Task</Button>
          </HStack>
          <Menu>
          <MenuButton
              as={IconButton}
              size="sm"
              variant="settingButton"
              colorScheme="forwardWhite"
              borderRadius="0px"
              icon={<AiOutlineSetting />}
              aria-label="Edit"
              mt={{'base':0,'md':0}}
              mr={{'base':1,'md':0}}
            />
          <MenuList>
            {!embed && <MenuItem onClick={handleEmbedLink}>Get Embed Link</MenuItem>}
            <MenuItem onClick={toggleAccordion}>{isExpanded ? 'Collapse' : 'Expand'}</MenuItem>
          </MenuList>

          </Menu>
          <AccordionButton display="none" ref={accordionButtonRef} size="xs" width="50px">
            <AccordionIcon size="sm" />
          </AccordionButton >
        </HStack>
        {/* Accordion Content */}
        <AccordionPanel>
          <AreaRemindersBox style="pinned" areaId='all' days={1} />
          {pinnedTasks && pinnedTasks.length > 0 && (
            <>
              <DragDropContext onDragEnd={handlePinnedDragEnd}>
              <TaskContainerBox 
            header={""}
            project_id={"pinned"} 
            tasks={pinnedTasks || []}
            view_to_show={'this_week'}
            timeframe_to_show='n/a' 
            width="100%"
            showAddButton={false}
            borderLeft="2px solid"
            borderColor={bdColor}
            showProjects='true'
            />
            </DragDropContext>
            </>
          )}
        { (!pinnedTasks || pinnedTasks.length ===0) &&
        <Box>
          <Center>
            <VStack width="100%">
          <Text>Pin a task to have it show up here!</Text>
          </VStack>
          </Center>
          </Box>
        }

        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    </Box>
  );
};


export default PinnedTasks;
