import React, { useState, useContext } from 'react';
import { HStack, Button, Text, useColorModeValue, VStack, IconButton, useDisclosure, Collapse, Flex, Tooltip, Menu, MenuList, MenuButton, MenuItem, Badge } from '@chakra-ui/react';
import { AiFillCheckCircle, AiOutlineSetting} from 'react-icons/ai';
import { BsFillSkipForwardFill} from 'react-icons/bs';
import ConfirmDialog from '../../general/components/ConfirmDialog.jsx';

import DelayButton from '../components/DelayButton.jsx';
import dhf from '../../../uc_utils/dates/dates.js';
import rhf from '../../../uc_utils/tasks/reminders.js';
import { useReminders } from '../../../data_hooks/elements/useReminders.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';

const ReminderRow = ({ reminder, handleAction, ...props}) => {
  const rem = useReminders();
  const {state } = useContext(ForwardContext);
  const { editReminder} = useContext(FormContext);
  const eventDate = dhf.createDateObject(reminder.next_due_date);
  const daysFromNow = dhf.getDaysBetweenDates(eventDate,new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isACOpen, setIsACOpen] = useState(false);
  const onACClose = () => setIsACOpen(false);

  const bgMode = useColorModeValue('light','dark');

  const bgColor = rhf.getReminderColor(daysFromNow, bgMode);

  const anniversary_count = rhf.getAnniversaryCount(reminder);

  let showActions = false;
  let paddingRight = "4px";
  if(daysFromNow <= 7) {
    //Within a week, present options to Complete | Delay | Skip
    showActions = true
    paddingRight = "0px"
  }

  const archiveReminder = async () => {
    rem.upsertReminderProperty(reminder, 'status', 'abandoned');
    onACClose();
  };

  const my_life_area = state.areas.filter(area => area.id === reminder.area_id);


  return (
    <VStack  
              mx={1} 
              width="100%" 
              justifyContent="space-between" 
              pl={2} 
              py={2}
              paddingRight={paddingRight} 
              mb={1} 
              boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;'
              backgroundColor={bgColor} 
              {...props}
              >
      <HStack width="100%" justifyContent="space-between">
        <HStack flex="2" width="100%">
          <VStack alignItems="flex-start" spacing={0}>
          <HStack><Text fontSize="md" fontWeight={daysFromNow <= 7 ? 'semibold' : ''} >{reminder.task_name}</Text>
          {reminder.cadence === 12 && <Badge fontSize="xs" colorScheme="green">{rhf.style_anniversary(anniversary_count)}</Badge>}
          </HStack>
          <Text fontSize="2xs" fontStyle="oblique">{my_life_area?.[0]?.area_name}</Text>
          </VStack>
          {/*<Button variant="unstyled" size="xs" fontSize="3xs" onClick={() => editReminderOpen(reminder)}>Edit</Button>*/}

        </HStack>
        <HStack >
        <Tooltip label={"on "+dhf.formatDate(eventDate,'MMM dd')} placement="bottom" hasArrow>
          <Text fontSize="xs" fontStyle="oblique" textAlign="right">in {daysFromNow} days</Text>
          </Tooltip>
          {showActions && 
            <>
              <Button size="xs" colorScheme="forwardOrange" fontSize="xs" borderRadius="0px" onClick={isOpen ? onClose : onOpen}>Actions</Button>
            </>
          }
          <Menu>
          <MenuButton
              as={IconButton}
              size="xs"
              variant="unstyled"
              borderRadius="0px"
              icon={<AiOutlineSetting />}
              aria-label="Edit"
              mt={{'base':0,'md':0}}
              mr={{'base':1,'md':0}}
            />
          <MenuList>
            <MenuItem onClick={() => editReminder(reminder)}>Edit</MenuItem>
            <MenuItem onClick={() => setIsACOpen(true)}>Archive</MenuItem>
          </MenuList>

          </Menu>
        </HStack>
      </HStack>
      <Collapse in={isOpen} >
              <HStack
                width="100%"
                py={1}
                spacing={2}
              >
                <VStack>
                <IconButton borderRadius={0} height="24px" width="50px" size="xs" icon={<AiFillCheckCircle />} colorScheme="green" onClick={() => handleAction(reminder, 'done')} />
                <Text fontSize="2xs" mt="0px !important">Complete</Text>
                </VStack>
                <DelayButton borderRadius={0} height="24px" width="50px"  reminder={reminder} handleAction={handleAction} />
                <VStack>
                <IconButton borderRadius={0} height="24px" width="50px"  size="xs" icon={<BsFillSkipForwardFill />} colorScheme="gray" onClick={() => handleAction(reminder, 'skip')}/>
                <Text fontSize="2xs" mt="0px !important">Skip</Text>
                </VStack>
              </HStack>
            </Collapse>
            <ConfirmDialog
              isOpen={isACOpen}
              onClose={onACClose}
              onConfirm={archiveReminder}
              title="Archive Project"
              description="Are you sure you want to archive (delete) this reminder?"
              confirmButtonText="Archive"
            />
      
      </VStack>
  );
  
};

export default ReminderRow;
