//I need a bar that shows the goals within each life area

import React from 'react';
import { Box, Flex, Text, VStack, Tooltip, HStack, useMediaQuery, useColorModeValue } from '@chakra-ui/react';
import GoalRow from './GoalRow';
import { ForwardContext } from '../../../context/ForwardContext';


const LifeAreaRow = ({ area, ...props }) => {
    const { state } = React.useContext(ForwardContext);
    const rowBg = useColorModeValue("forwardWhite.100", "forwardBlue.700");
    const textColor = useColorModeValue("gray.800", "gray.200");
    const bdColor = useColorModeValue("gray.200", "gray.700");
    const goals = state.new_goals[area.id] || [];
    const [isMobile] = useMediaQuery("(max-width: 600px)");

    return (
        <HStack width="100%" bg={rowBg} borderRadius="md" p={2} mb={2} flexDirection={isMobile ? 'column' : 'row'}>
            <Box flex="1" minWidth="100px" textAlign="left" fontSize="xs" color={textColor} fontWeight="bold">{area.area_name}</Box>
            <VStack flex="21" align="stretch" spacing={0} borderWidth={1} borderColor={bdColor} width="100%">
            {goals.map((goal) => {
                return (
                    <GoalRow key={`${goal.id}-${state.grid_ui.selected_level}-${state.grid_ui.visible_base_level}`} goal={goal} />
                )
            }
            )}
            </VStack>
        </HStack>
    )
}


export default LifeAreaRow;