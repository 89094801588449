import React from 'react';
import { Box, VStack, Text, Popover, PopoverTrigger, Button, useDisclosure, Tooltip } from '@chakra-ui/react';
import TaskPeriodContainerBox from '../boxes/TaskPeriodContainerBox';
import { ForwardContext } from '../../../context/ForwardContext';
import dhf from '../../../uc_utils/dates/dates.js';

const TaskCell = ({ period, length, cell_width, itemData }) => {
  // Filter the forward_new_goal_entries based on the period
  //console.log("Filtered Entries: ", filteredEntries);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const cellbg="gray.200";
  const { state } = React.useContext(ForwardContext);
  //console.log("Task Cell: ", itemData);
  const task_categories = state?.account?.task_categories || [];

  // If the period is longer than one day, I need to aggregate the tasks
  const filteredEntries = React.useMemo(() => {
    if (!itemData || !period.start || !period.end) return [];

    const start = new Date(period.start);
    const end = new Date(period.end);
    const show_call_tasks = state.grid_ui.show_all_tasks;
    let current = new Date(start);
    let aggregatedTasks = [];

    while (current <= end) {
      const dateKey = current.toISOString().split('T')[0];
      let tasksForDay = itemData[dateKey]?.filter((task) => task.item_status !== 'abandoned') || [];
      if(show_call_tasks === false) {
        tasksForDay = tasksForDay.filter((task) => task.item_status !== 'complete');
      }
      aggregatedTasks = [...aggregatedTasks, ...tasksForDay];

      current.setDate(current.getDate() + 1); // Move to next day
    }

    return aggregatedTasks;
  }, [itemData, period.start, period.end, state.grid_ui.show_all_tasks]);

  return (

    <Popover
        isOpen={isOpen}
        onClose={onClose}
        height="100%"
    >
      <PopoverTrigger width="100%">
      
    <Box bg={cellbg} width="100%" border="1px solid" borderColor="gray.300" height="100%" p={0} cursor="pointer">
      <VStack alignItems="stretch" spacing={0} width="100%" height="100%">

        {/*<TaskPeriodContainerBox 
          tasks={filteredEntries.filter((task) => task.is_pinned === true)} 
          date={period.start} 
          task_category={0}
          task_list="pinned" />*/}
        {task_categories.map((category) => {
          return (
            <VStack key={dhf.formatDate(period.start)+'-'+category.id} align="start" spacing={0} width="100%" height="100%">
              <TaskPeriodContainerBox 
                  key={dhf.formatDate(period.start)+'-'+category.id}
                  tasks={filteredEntries.filter((task) => task.task_category === category.id)} 
                  date={dhf.formatDate(period.start)} 
                  task_category={category}
                  task_list="planned"
                  height="100%"
              />
            </VStack>
          );
        }
        )}
      </VStack>
    </Box>
    </PopoverTrigger>

    </Popover>
  );
};

export default TaskCell;