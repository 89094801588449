import React from 'react';
import { Box, VStack, Text, Popover, PopoverTrigger, Button, useDisclosure, Tooltip } from '@chakra-ui/react';
import HabitCell from '../../habits/rows/HabitCell';
import HabitGoalGridWeeklyCell from './HabitGoalGridWeeklyCell';
import dhf from '../../../uc_utils/dates/dates.js';
import { ForwardContext } from '../../../context/ForwardContext.jsx';

const NewHabitCell = ({ period, length, cell_width, itemData }) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const { state } = React.useContext(ForwardContext);
  //If the length is 1, we just want this to be a habit cell.
  //console.log('Item Data: ', itemData);

  const cellbg='gray.200';
  const isDisabled = false;

  const onToggle = () => {
    onOpen();
  }
  const my_area_id = itemData?.habit_data?.area_id || null;

  const myHabit = state?.habits?.[my_area_id]?.filter((habit) => habit.id === itemData.habit_data.id)[0] || {};

  return (

    <Popover
        isOpen={isOpen}
        onClose={onClose}
    >
      <PopoverTrigger>
    <Box bg={cellbg} border="1px solid" borderColor="gray.900" width="100%" height="100%" p={0} cursor="pointer" onClick={() => onToggle()} overflow="clip">
    <Tooltip label={"Habit"} aria-label="A tooltip">
      <VStack align="start" width="100%" spacing={0}>
        {itemData?.calc_level === 'days' ? 
        (<HabitCell 
          key={itemData?.habit_data?.id} 
          habit={myHabit} 
          liveDay={dhf.getLocalMidnightDate(period.start)} 
          newView={true} />)
    : (
      <HabitGoalGridWeeklyCell 
                area_id = {itemData.habit_data.area_id} 
                habit_id= {itemData.habit_data.id} 
                startDate={period.start} 
                backgroundColor={'gray.200'} 
                toDoOnClick="set_target" 
                showHoverText={true} 
                borderWidth="1px 0px 1px 0px"
                cell_width={cell_width}
                showValues={true}
                />
    )}
      </VStack>
      </Tooltip>
    </Box>
    </PopoverTrigger>

    </Popover>
  );
};

export default NewHabitCell;