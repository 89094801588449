import { Box, Flex, HStack, VStack, Button, Text, useColorModeValue, IconButton, ButtonGroup, 
  Menu, MenuButton, MenuItem, MenuList, useToast,
  Spacer} from '@chakra-ui/react';
import React, { useState, useContext, useEffect } from 'react';
import { AiOutlineSwap, AiOutlineSetting } from 'react-icons/ai';
import TaskContainerBox from '../../tasks/boxes/TaskContainerBox.jsx';
import GoalContainerBox from '../../goals/boxes/GoalContainerBox.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';
import { useProjects } from '../../../data_hooks/elements/useProjects.jsx';
import ConfirmDialog from '../../general/components/ConfirmDialog.jsx';
import { useNavigate } from 'react-router-dom';
import c from '../../../uc_constants/fwd_constants.js';


const ProjectRow = ({ project, tasks, viewMode='project', embed=false, ...props}) => {
  const toast = useToast();
  const {state, dispatch} = useContext(ForwardContext);
  const { editProject, addGoal, editGoal } = useContext(FormContext);
  const navigate = useNavigate();
  const my_goals = state.goals;
  const pr = useProjects();
  const is_ghost_project = project.project_type === 2 ? true:false;
  const is_paused = project.status === 2 ? true:false;
  const is_archived = project.status === 3 ? true:false;

  const showHeader = !(viewMode==='sp_backlog');
  const showGoals = !(viewMode==='sp_backlog');

  const borderColor = useColorModeValue("forwardWhite.600","forwardBlue.900");
  const backgroundColor = useColorModeValue("forwardWhite.500","forwardBlue.800");
  const pausedBackgroundColor = useColorModeValue("forwardWhite.700","forwardWhite.900")
  
  const [isACOpen, setIsACOpen] = useState(false);
  const onACClose = () => setIsACOpen(false);

  const handleEmbedLink = (project) => {
    const linkText = c.forward_main_url+'/embed/project/'+project.id+'';
    navigator.clipboard.writeText(linkText)
      .then(() => {
        toast.closeAll(); // Close all existing toasts
        toast({
          title: 'Link copied to clipboard',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Failed to copy link to clipboard:', error);
        toast.closeAll(); // Close all existing toasts
        toast({
          title: 'Failed to copy link to clipboard',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  }


  let list_to_show = 'current_tasks';
  if (state.ui.currentWeek.toISOString() === state.ui.thisWeek.toISOString()) {
    list_to_show = 'this_week';
  }
  else if (state.ui.currentWeek.toISOString() === state.ui.nextWeek.toISOString()) {
    list_to_show = 'next_week';
  }

  const unpauseProject = async () => {
    pr.upsertProjectProperty(project,'status',1)
  }
  const pauseProject = async () => {
    pr.upsertProjectProperty(project,'status',2)
  }
  const archiveProject = async () => {
    pr.upsertProjectProperty(project, 'status', 3);
    pr.upsertProjectProperty(project,'order_id',999);
    onACClose();
  };

  return (
    <Box width="100%" maxWidth="100%"  borderColor={borderColor} mb={2} boxShadow="md" >
      <VStack width="100%" spacing={0}>
    {(showHeader || embed) && 
    <Flex px={2} paddingTop={2} paddingBottom={1} width="100%" backgroundColor={(is_paused || is_archived) ? pausedBackgroundColor : backgroundColor} 
    alignItems="center" alignContent="center" justifyContent="space-between"
     borderBottom="1px" borderColor={borderColor}>
        <HStack width="100%" justifyContent="space-between">
        {embed ? (<HStack>
          <Text fontWeight="bold" fontSize="lg" >{project.project_name}</Text>
          </HStack>) 
        : (<Text 
                fontWeight="bold" 
                fontSize="lg" 
                m={0}
                _hover={{ textDecoration:"underline" }} 
                onClick={() => navigate('/forward/project/'+project.id)}
                cursor="pointer"
                >{project.project_name}</Text>)}
                <Spacer />
          {is_paused && <Text fontStyle="oblique">Paused</Text>}
          {is_archived && <Text fontStyle="oblique">Archived</Text>}
          {is_ghost_project && <Text fontStyle="italic" fontSize="sm">Default</Text>}
          <ButtonGroup>
          <Menu>
            <MenuButton
              as={IconButton}
              size="xs"
              borderRadius="0px"
              icon={<AiOutlineSetting />}
              aria-label="Edit"
              mt={{'base':0,'md':1}}
              mr={{'base':1,'md':0}}
            />
            <MenuList>
              <MenuItem onClick={() => editProject(project)}>Edit</MenuItem>
              {project.status===2 && <MenuItem onClick={unpauseProject}>Unpause</MenuItem>}
              {project.status===1 && <MenuItem onClick={pauseProject}>Pause</MenuItem>}
              {!embed && <MenuItem fontSize="md" onClick={() => handleEmbedLink(project)}>Get Embed Link</MenuItem>}
              <MenuItem onClick={() => setIsACOpen(true)}>Archive</MenuItem>

            </MenuList>
            </Menu>
          </ButtonGroup>
        </HStack>

    </Flex>}
    {(showGoals && !is_paused) && 
      <GoalContainerBox 
          project={project} 
          goals={my_goals} 
          addGoalOpen={addGoal}
          editGoalOpen={editGoal}
           />}
    <Flex 
    width="100%" 
    maxWidth="100%"
    alignContent="stretch" 
    alignItems="stretch" 
    justifyContent="space-between" 
    flexDirection={{'base':'column','md':'row'}}
    backgroundColor={backgroundColor}
    >
    {(viewMode==='project' && !is_paused) && <>
    <TaskContainerBox 
            header="To Do" 
            project_id={project.id} 
            tasks={(tasks && tasks[list_to_show]) || []}
            view_to_show={list_to_show}
            timeframe_to_show='currentweek' 
            width="100%"
            maxWidth="100%"
            showAddButton={true} />
    <TaskContainerBox 
            header="Accomplished" 
            project_id={project.id} 
            tasks={(tasks['accomplishments']) || []}
            view_to_show="accomplishments" 
            timeframe_to_show='currentweek' 
            width="100%"
            maxWidth="100%"
            showAddButton={true} />
            </>
            }
            {((viewMode==='backlog' || viewMode==='sp_backlog' || viewMode==='kanban') && !is_paused)&& 
            <>
            <TaskContainerBox 
            header="Backlog" 
            project_id={project.id} 
            tasks={(tasks && tasks['backlog']) || []}
            view_to_show="backlog" 
            timeframe_to_show='currentweek' 
            overflow="hidden"
            mx={{'base':0,'md':2}}
            my={{'base':2,'md':1}}
            width="100%"
            maxWidth="100%"
            showAddButton={true} />
    <TaskContainerBox 
            header="Priority" 
            project_id={project.id} 
            tasks={(tasks && tasks['priority']) || []}
            view_to_show="priority" 
            timeframe_to_show='currentweek' 
            overflow="hidden"
            mx={{'base':0,'md':2}}
            my={{'base':2,'md':1}}
            width="100%"
            showAddButton={true} />
      <TaskContainerBox 
            header="To Schedule" 
            project_id={project.id} 
            tasks={(tasks && tasks['toschedule']) || []}
            view_to_show="toschedule" 
            timeframe_to_show='currentweek' 
            overflow="hidden"
            mx={{'base':0,'md':2}}
            my={{'base':2,'md':1}}
            width="100%"
            showAddButton={true} />
    <TaskContainerBox 
            header="Next Week" 
            project_id={project.id} 
            tasks={(tasks && tasks['next_week']) || []}
            view_to_show="next_week" 
            timeframe_to_show='currentweek' 
            overflow="hidden"
            mx={{'base':0,'md':2}}
            my={{'base':2,'md':1}}
            width="100%"
            showAddButton={true} />
    <TaskContainerBox 
            header="This Week" 
            project_id={project.id} 
            tasks={(tasks && tasks['this_week']) || []}
            view_to_show="this_week" 
            timeframe_to_show='currentweek' 
            overflow="hidden"
            mx={{'base':0,'md':2}}
            my={{'base':2,'md':1}}
            width="100%"
            showAddButton={true} />
            </>
            
            
            }
    </Flex>
    </VStack>
    <ConfirmDialog
      isOpen={isACOpen}
      onClose={onACClose}
      onConfirm={archiveProject}
      title="Archive Project"
      description="Are you sure you want to archive this project?"
      confirmButtonText="Archive"
    />
    </Box>
  );
};

export default ProjectRow;
