import React, { useState, useEffect } from 'react';
import { 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Button,
  ButtonGroup,
  useColorModeValue
} from "@chakra-ui/react";

// Import your form components
import LifeAreaForm from '../../areas/forms/LifeAreaForm';
import HabitForm from '../../habits/forms/HabitForm';
import ProjectForm from '../../projects/forms/ProjectForm';
import GoalForm from '../../goals/forms/GoalForm';
import FullTaskForm from '../../tasks/forms/FullTaskForm';
import ReminderForm from '../../reminders/forms/ReminderForm';
import EventForm from '../../events/forms/EventForm';
import NewGoalForm from '../../new_goals/forms/NewGoalForm';

const FormModal = ({ isOpen, onClose, initialForm, session, showOptions, optionsToShow, editorData, isDisabled }) => {
  
  /* Sample Call:
  <FormModal
            isOpen={isModalOpen}
            onClose={closeModal}
            initialForm={state.ui.currentForm}  // The initial form identifier
            showOptions={false}
            optionsToShow='edit_only'
            session={session}
            editorData={state.ui.editorData}  // The data to edit, if any
            />
            */


  const brdColor=useColorModeValue('forwardWhite.800','forwardBlue.700');
  const formComponents = {
    'lifeArea': <LifeAreaForm isOpen={isOpen} 
                              onClose={onClose} 
                              session={session} 
                              optionsToShow={optionsToShow} 
                              editorData={editorData} 
                              isDisabled={isDisabled} />,
    'habit': <HabitForm       isOpen={isOpen} 
                              onClose={onClose} 
                              session={session} 
                              optionsToShow={optionsToShow} 
                              editorData={editorData} 
                              isDisabled={isDisabled} />,
    'project': <ProjectForm   isOpen={isOpen} 
                              onClose={onClose} 
                              session={session} 
                              optionsToShow={optionsToShow} 
                              editorData={editorData} 
                              isDisabled={isDisabled} />,
    'goal': <GoalForm         isOpen={isOpen} 
                              onClose={onClose} 
                              session={session} 
                              optionsToShow={optionsToShow} 
                              editorData={editorData}
                              isDisabled={isDisabled} />,
    'task': <FullTaskForm     isOpen={isOpen} 
                              onClose={onClose} 
                              session={session} 
                              optionsToShow={optionsToShow} 
                              editorData={editorData}
                              isDisabled={isDisabled} />,
    'reminder':<ReminderForm  isOpen={isOpen} 
                              onClose={onClose} 
                              session={session} 
                              optionsToShow={optionsToShow} 
                              editorData={editorData}
                              isDisabled={isDisabled} />,
    'event': <EventForm       isOpen={isOpen}
                              onClose={onClose}
                              session={session}
                              optionsToShow={optionsToShow}
                              editorData={editorData}
                              isDisabled={isDisabled} />,
    'new_goal': <NewGoalForm  isOpen={isOpen}
                              onClose={onClose}
                              session={session}
                              optionsToShow={optionsToShow}
                              editorData={editorData}
                              isDisabled={isDisabled} />,
  };
  const formTitles = {
    'lifeArea': 'Add a new life area',
    'habit':'Add a new habit',
    'project':'Add a new project',
    'goal':'Add a new goal',
    'task':'Add Task',
    'reminder':'Add a Reminder',
    'event':'Add Event',
    'new_goal':'Add a new goal',
  }

  const [activeForm, setActiveForm] = useState(initialForm);
  const [modalTitle,setModalTitle] = useState(editorData?.['modal_title'] || formTitles[initialForm]);

  useEffect(() => {
    setActiveForm(initialForm);
    setModalTitle(editorData?.['modal_title'] || formTitles[initialForm]);
  }, [initialForm]);
  //console.log(activeForm);
 
  const handleSwitchTo = (formToShow) => {
    //console.log(formToShow);
    setActiveForm(formToShow);
    setModalTitle(editorData?.['modal_title'] || formTitles[initialForm]);

  }
  // Map form identifiers to form components
  return (
    <Modal isOpen={isOpen} onClose={onClose} borderRadius="0px" autoFocus={false} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent borderRadius="0px" >
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />

        {/* Add a button group for form selection */}
        {showOptions && 
        <ButtonGroup variant="outline" spacing="0" m={4} borderRadius="0px">
          <Button
            borderRadius="0px"
            variant="solid"
            colorScheme = {activeForm === 'lifeArea' ? 'forwardBlue':'gray'}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleSwitchTo('lifeArea')}
            disabled={activeForm === 'lifeArea'}
          >
            Life Area
          </Button>
          <Button
            borderRadius="0px"
            variant="solid"
            colorScheme = {activeForm === 'habit' ? 'forwardBlue':'gray'}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleSwitchTo('habit')}
            disabled={activeForm === 'habit'}
          >
            Habit
          </Button>
          <Button
            borderRadius="0px"
            variant="solid"
            colorScheme = {activeForm === 'project' ? 'forwardBlue':'gray'}
            borderRight="1px"
            borderColor={brdColor}
            onClick={() => handleSwitchTo('project')}
            disabled={activeForm === 'project'}
          >
            Project
          </Button>
          <Button
          borderRadius="0px"
          variant="solid"
          colorScheme = {activeForm === 'goal' ? 'forwardBlue':'gray'}
          borderRight="1px"
          borderColor={brdColor}
            onClick={() => handleSwitchTo('goal')}
            disabled={activeForm === 'goal'}
          >
            Goal
          </Button>
          <Button
          borderRadius="0px"
          variant="solid"
          colorScheme = {activeForm === 'task' ? 'forwardBlue':'gray'}
          borderRight="1px"
          borderColor={brdColor}
          onClick={() => handleSwitchTo('task')}
          isDisabled={activeForm === 'task'}
          >
            Task
          </Button>
          <Button
          borderRadius="0px"
          variant="solid"
          colorScheme = {activeForm === 'reminder' ? 'forwardBlue':'gray'}
          onClick={() => handleSwitchTo('reminder')}
          disabled={activeForm === 'reminder'}
          >
            Reminder
          </Button>
        </ButtonGroup>}

        {/* Render the active form */}
        {formComponents[activeForm]}
      </ModalContent>
    </Modal>
  );
};

export default FormModal;
