import React, {useMemo} from 'react';
import { Box, Flex, Text, VStack, Tooltip, HStack, Button, useMediaQuery, useColorModeValue } from '@chakra-ui/react';
import TaskCell from '../cells/TaskCell';
import { generateCells } from '../helpers/cells';
import { ForwardContext } from '../../../context/ForwardContext';
import { DragDropContext } from '@hello-pangea/dnd';
import { useTasks } from '../../../data_hooks/elements/useTasks';
import dhf from '../../../uc_utils/dates/dates.js';
import TaskPeriodContainerBox from '../boxes/TaskPeriodContainerBox.jsx';

const TasksRow = ({ ...props }) => {
    const { state, dispatch } = React.useContext(ForwardContext);
    const [isMobile] = useMediaQuery("(max-width: 600px)");
    const tasks = state.new_tasks.tasks_by_date || {};
    const task_categories = state?.account?.task_categories || [];

    const rowBg = useColorModeValue("forwardWhite.100", "forwardBlue.700");
    const bdColor = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.800", "gray.200");
    const cellBg = useColorModeValue("forwardWhite.200", "forwardBlue.800");

    const [isPriorityCollapsed, setIsPriorityCollapsed] = React.useState(true);
    const [isBacklogCollapsed, setIsBacklogCollapsed] = React.useState(true);
    const [isScheduledCollapsed, setIsScheduledCollapsed] = React.useState(false);
    const toggleScheduledCollapse = () => {
      setIsScheduledCollapsed(!isScheduledCollapsed);
    };
    const togglePriorityCollapse = () => {
      setIsPriorityCollapsed(!isPriorityCollapsed);
    };
    const toggleBacklogCollapse = () => {
      setIsBacklogCollapsed(!isBacklogCollapsed);
    };
    //const [showCompleted, setShowCompleted] = React.useState(state.grid_ui.show_all_tasks);
    const cell_type = TaskCell;
    const ut = useTasks();
    let calc_level = 'days';
    const { start_date, end_date, selected_level } = state.grid_ui;


    //console.log(state.new_tasks);
    const backlog_tasks = state?.new_tasks?.tasks_by_date?.[null] || [];
    if (selected_level === 'months') {
        calc_level = 'weeks';
    }
    else if (selected_level === 'quarters') {
        calc_level = 'weeks';
    }
    else if (selected_level === 'years') {
        calc_level = 'months';
    }

    const handleShowToggle = () => {
      const new_value = !state.grid_ui.show_all_tasks;
        dispatch({ type: 'SET_SHOW_ALL_TASKS', payload: new_value });
        //setShowCompleted(new_value);
    }
    const cells = useMemo(() => {
       
      return generateCells(start_date, end_date, calc_level, cell_type, tasks);
    }, [tasks, start_date, end_date]);

    const handleDragEndWell = (result) => {
      console.log("Drag End: ", result);
      const { destination, source, draggableId } = result;
      //DraggableID = Task ID to handle
      //Source = Where it came from
      //Destination = Where it is going
      //If the destination is null, then it was not dropped in a valid location
      if(!destination) {
        return;
      }
      //If the source and destination are the same, then we need to check the indexes and update the ordering for that day
      if(source.droppableId === destination.droppableId) {

      }
      else {
      //If the source and destination are different, then we need to update the task's date, category, order_id, and is_pinned to reflect the new cell
      const destination_split_id = destination.droppableId.split('-');
      const source_split_id = source.droppableId.split('-');
      const d_year = destination_split_id[3];
      const d_month = destination_split_id[4];
      const d_day = destination_split_id[5];
      let old_date = state.new_tasks.task_id_to_date[parseInt(draggableId)];
      let new_date = new Date(d_year, d_month-1, d_day);
      console.log("Old Date: ", old_date);
      console.log("New Date: ", new_date);
      console.log(state.new_tasks);
      let old_task_object = {};
      if(old_date === null) 
      {
        old_task_object = state?.new_tasks?.tasks_by_date?.['null'].find((task) => parseInt(task.id) === parseInt(draggableId));
      }
      else if ( old_date === 'null') {
        old_task_object = state?.new_tasks?.tasks_by_date?.['null'].find((task) => parseInt(task.id) === parseInt(draggableId));
      }
      else {
        old_task_object = state?.new_tasks?.tasks_by_date?.[dhf.formatDate(old_date)].find((task) => parseInt(task.id) === parseInt(draggableId));
      }
      console.log("Old Task Object: ", old_task_object);
      const destination_list = destination_split_id[7];
      const destination_category = destination_split_id[9];
      let new_category = old_task_object.task_category;
      let is_pinned = old_task_object.is_pinned;
      let new_item_status = old_task_object.item_status;
      if (destination_list === 'pinned') {
        is_pinned = true;
        new_item_status = 'planned';
      }
      else if (destination_list === 'toschedule') {
        new_item_status = 'toschedule';
        new_date = null;
      }
      else if (destination_list === 'priority') {
        new_item_status = 'priority';
        new_date = null;
      }
      else if (destination_list === 'backlog') {
        new_item_status = 'backlog';
        new_date = null;
      }
      else {
        new_item_status = 'planned';
        //is_pinned = false;
      }

      if (parseInt(destination_category) > 0) {
        new_category = parseInt(destination_category);
      }

      const new_task_data = {
        id: parseInt(draggableId),
        project_id: parseInt(old_task_object.project_id),
        item_planned_week: dhf.formatDate(new_date),
        item_status: new_item_status,
        task_category: parseInt(new_category),
        order_id: 0,
        is_pinned: is_pinned
      }
      console.log("New Task Data: ", new_task_data);
      //Now update the database
      //And then update state
      ut.addNewTask({
        id: parseInt(draggableId),
        project_id: parseInt(old_task_object.project_id),
        item_planned_week: dhf.formatDate(new_date),
        item_status: new_item_status,
        task_category: parseInt(new_category),
        order_id: 0,
        is_pinned: is_pinned
      });
      }
    }


    return (
      <DragDropContext onDragEnd={handleDragEndWell}>
        <HStack width="100%" bg={rowBg} borderRadius="md" p={2} mb={2} alignItems="stretch" flexDirection={isMobile ? 'column-reverse' : 'row'} >
          <VStack>
            <HStack flex="1" minWidth={isMobile ? "100%" : "150px"} textAlign="left" fontSize="xs" color={textColor}>
              <Text>Tasks</Text>
              <Text textDecoration="underline" cursor="pointer" onClick={handleShowToggle}>{state.grid_ui.show_all_tasks ? 'All' : 'To Do'}</Text>
            </HStack>
            <Box flex="12" bg={cellBg} minWidth={isMobile ? "100%" : "150px"} maxWidth={isMobile ? "100%" : "150px"}>
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between"  borderBottom="1px solid">
                <Text width="100%" textAlign="center" fontSize="xs">
                  To Schedule ({state?.new_tasks?.tasks_by_date?.[null]?.filter((task) => task.item_status === 'toschedule').length})
                </Text>
                <Button onClick={toggleScheduledCollapse} size="sm" ml={2}  borderRadius={0} variant="ghost">
                  {isScheduledCollapsed ? '+' : '-'}
                </Button>
              </Box>
              {!isScheduledCollapsed && 
                (task_categories.map((category) => { 
                
                return (<TaskPeriodContainerBox
                  key={'tc-toschedule-'+category.id}
                  tasks={state?.new_tasks?.tasks_by_date?.[null]
                          ?.filter((task) => task.item_status === 'toschedule')
                          ?.filter((task) => task.task_category === category.id)}
                  date={null}
                  task_list="toschedule"
                  
                  task_category={category}
                />)
              }))
              }
            </Box>
              <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between"  borderBottom="1px solid">
                <Text width="100%" textAlign="center" fontSize="sm">
                  Priority ({state?.new_tasks?.tasks_by_date?.[null]?.filter((task) => task.item_status === 'priority').length})
                </Text>
                <Button onClick={togglePriorityCollapse} size="sm" ml={2}  borderRadius={0} variant="ghost">
                  {isPriorityCollapsed ? '+' : '-'}
                </Button>
              </Box>
              {!isPriorityCollapsed && (
                <TaskPeriodContainerBox
                  key={'tc-priority-0'}
                  tasks={state?.new_tasks?.tasks_by_date?.[null]?.filter((task) => task.item_status === 'priority')}
                  date={null}
                  task_list="priority"
                  task_category={0}
                />
              )}
            </Box>
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" borderBottom="1px solid">
                <Text width="100%" textAlign="center" fontSize="sm">
                  Backlog ({state?.new_tasks?.tasks_by_date?.[null]?.filter((task) => task.item_status === 'backlog').length})
                </Text>
                <Button onClick={toggleBacklogCollapse} size="sm" ml={2} borderRadius={0} variant="ghost">
                  {isBacklogCollapsed ? '+' : '-'}
                </Button>
              </Box>
              {!isBacklogCollapsed && (
                <TaskPeriodContainerBox
                  key={'tc-backlog-0'}
                  tasks={state?.new_tasks?.tasks_by_date?.[null]?.filter((task) => task.item_status === 'backlog')}
                  date={null}
                  task_list="backlog"
                  task_category={0}
                />
              )}
            </Box>
            </Box>
            </VStack>
            <HStack alignItems="stretch" 
            width={isMobile ? "100%" : "calc(100% - 150px)"}
            bg="gray.100" borderRadius="md" p={0} mb={0} spacing={0}>
            {!isMobile && <VStack align="start" spacing={0} width="100%" minWidth="50px" maxWidth="50px" flex="1">
              
                {/*<Box flex="1" width="100%" bg="gray.100" minHeight="100px" borderBottom="1px solid" p={0} textAlign="left" display="flex" alignItems="center">
                  <Text  transform="rotate(90deg)">Pinned</Text>
                </Box>*/}
                {state.account.task_categories.map((category) => {
                    return (
                    <Box flex="1" key={category.id} width="100%" bg={cellBg} minHeight="100px" borderBottom="1px solid" p={0} textAlign="center" display="flex" alignItems="center">
                      <Text  transform="rotate(90deg)">{category.value}</Text>
                    </Box>
                    );
                }
                )}
                
              </VStack>  }            
        <HStack flex="20" spacing={0} borderColor="gray.200" width="100%" alignItems="stretch" justifyContent="stretch" minHeight="32px">
        
          {cells}
        
        </HStack>
      </HStack>
        </HStack>
        </DragDropContext>
    )
}


export default TasksRow;