import dhf from '../uc_utils/dates/dates.js'

  // Helper function to ensure all task IDs are integers
  const ensureIntegerIds = (tasks) => tasks.map(task => ({
    ...task,
    id: parseInt(task.id)
  }));
  

const allTaskReducer = (new_tasks, action) => {
  switch (action.type) {
    case 'SET_TASKS_BY_DATE':
      const allTasks = action.payload;
      const task_id_to_date = {};
      Object.entries(allTasks)
      .forEach(([date, tasks]) => {
        tasks.forEach(task => {
          task_id_to_date[task.id] = date;
        });
      });

      return {
        new_tasks: {
          tasks_by_date: allTasks,
          task_id_to_date: task_id_to_date,
        }
      };

    case 'SET_TASKS_DATE_RANGE':
      const task_subset = action.payload;
      const new_tasks_by_date = {...new_tasks.tasks_by_date};
      const new_task_id_to_date = {...new_tasks.task_id_to_date};
      for (const [date, tasks] of Object.entries(task_subset)) {
        new_tasks_by_date[date] = tasks;
        tasks.forEach(task => {
          new_task_id_to_date[task.id] = date;
        });
      }
      return {
        new_tasks: {
          tasks_by_date: new_tasks_by_date,
          task_id_to_date: new_task_id_to_date,
        }
      };
      case 'NEW_UPDATE_TASK':
  const { taskId, oldDate, newDate, updatedContent } = action.payload;

  console.log('Updating task', taskId, 'from', oldDate, 'to', newDate, 'with', updatedContent);
  
  // Helper function to get the correct date key
  const getDateKey = (date) => (date === null || date === 'null') ? 'null' : dhf.formatDate(date);
  
  const oldDateKey = getDateKey(oldDate);
  const newDateKey = getDateKey(newDate);
  
  // If the task is not moving to a new date, just update the content
  if (oldDateKey === newDateKey) {
    return {
      new_tasks: {
        ...new_tasks,
        tasks_by_date: {
          ...new_tasks.tasks_by_date,
          [oldDateKey]: ensureIntegerIds(
            new_tasks.tasks_by_date[oldDateKey]
              .map(task =>
                parseInt(task.id) === parseInt(taskId) ? { ...task, ...updatedContent } : task
              ))
              .sort((a, b) => {
                if (a.is_pinned === b.is_pinned) {
                  return a.item_name.localeCompare(b.item_name);
                }
                return a.is_pinned ? -1 : 1;
              })
        }
      }
    };
  }

  // If the task is moving to a new date
  const tasksAtOldDate = new_tasks.tasks_by_date[oldDateKey] || [];
  const taskToMove = tasksAtOldDate.find(task => task.id === parseInt(taskId));
  
  if (!taskToMove) {
    console.error(`Task with id ${taskId} not found in ${oldDateKey}`);
    return {new_tasks:new_tasks}; // Return unchanged state if task not found
  }

  return {
    new_tasks: {
      ...new_tasks,
      tasks_by_date: {
        ...new_tasks.tasks_by_date,
        // Remove the task from the old date
        [oldDateKey]: tasksAtOldDate.filter(task => task.id !== parseInt(taskId)),
        // Add the updated task to the new date
        [newDateKey]: [
          ...(new_tasks.tasks_by_date[newDateKey] || []),
          { ...taskToMove, ...updatedContent, item_planned_week: newDate }
        ].sort((a, b) => {
          if (a.is_pinned === b.is_pinned) {
            return a.item_name.localeCompare(b.item_name);
          }
          return a.is_pinned ? -1 : 1;
        })
      },
      task_id_to_date: {
        ...new_tasks.task_id_to_date,
        [taskId]: newDate
      }
    }
  };

      // Update other cases to use this helper function
      case 'NEW_ADD_TASK':
        const task = action.payload;
        const date = task.item_planned_week;
        return {
          new_tasks: {
            tasks_by_date: {
              ...new_tasks.tasks_by_date,
              [date]: ensureIntegerIds([...(new_tasks.tasks_by_date[date] || []), task])
              .sort((a, b) => {
                if (a.is_pinned === b.is_pinned) {
                  return a.item_name.localeCompare(b.item_name);
                }
                return a.is_pinned ? -1 : 1;
              })
            },
            task_id_to_date: {
              ...new_tasks.task_id_to_date,
              [parseInt(task.id, 10)]: date
            }
          }
        };
      
      case 'NEW_REMOVE_TASK':
        const { taskIdToRemove } = action.payload;
        const dateOfTaskToRemove = new_tasks.task_id_to_date[taskIdToRemove];
        const { [taskIdToRemove]: _, ...remainingTaskIdToDate } = new_tasks.task_id_to_date;
        return {
          new_tasks: {
            tasks_by_date: {
              ...new_tasks.tasks_by_date,
              [dateOfTaskToRemove]: ensureIntegerIds(new_tasks.tasks_by_date[dateOfTaskToRemove]
          .filter(task => task.id !== parseInt(taskIdToRemove, 10)))
          .sort((a, b) => {
            if (a.is_pinned === b.is_pinned) {
              return a.item_name.localeCompare(b.item_name);
            }
            return a.is_pinned ? -1 : 1;
          })
            },
            task_id_to_date: remainingTaskIdToDate
          }
        };
    default:
      return { new_tasks };
  }
};

export default allTaskReducer;