import React, { useEffect, useMemo, useCallback } from 'react';
import { Box, HStack, useMediaQuery, useColorModeValue } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import { generateCells } from '../helpers/cells';
import StandardCell from '../cells/StandardCell';
import NewHabitCell from '../cells/NewHabitCell';

const GoalRow = React.memo(({ goal }) => {
    const { state } = React.useContext(ForwardContext);
    const { start_date, end_date, selected_level, visible_base_level } = state.grid_ui;
    const [isMobile] = useMediaQuery("(max-width: 600px)");
    const bgColor = useColorModeValue("forwardWhite.100", "forwardBlue.700");
    const textColor = useColorModeValue("gray.800", "gray.200");
    const bdColor = useColorModeValue("gray.200", "gray.700");

    //console.log("GoalRow: ", goal);

    // Generate cells with memoized values
    const cells = useMemo(() => {
        //console.log("GoalRow: ", goal);
        //console.log("Start Date: ", start_date);
        //console.log("End Date: ", end_date);
        //console.log("Visible Base Level: ", visible_base_level);

        let calc_level = goal.timeframe;
        let cell_type = StandardCell;
        let habitData = null
        if (goal.goal_type === 'habit') 
        {
            cell_type = NewHabitCell;
            habitData = state?.habits?.[goal.area_id]?.find(habit => habit.id === goal.habit_id) || null;
            //console.log("Habit Data: ", habitData);
            if(selected_level === 'days' || selected_level === 'weeks' || selected_level === 'months') {
                calc_level = 'days';
            }
            else {
                calc_level = 'weeks';
            }

        }

      return generateCells(start_date, end_date, calc_level, cell_type, {
        id: goal.id,
        goal_name: goal.goal_name,
        area_id: goal.area_id,
        user_id: goal.user_id,
        forward_new_goal_entries: goal.forward_new_goal_entries,
        timeframe: goal.timeframe,
        habit_data: habitData,
        calc_level: calc_level,
      });
    }, [start_date, end_date, visible_base_level, goal]);

  
    return (
      <HStack width="100%" bg={bgColor} borderRadius={0} p={0} mb={0} spacing={0} flexDirection={isMobile ? 'column' : 'row'} alignItems="stretch">
        <Box flex="1" minWidth="100px" textAlign="left" fontSize="xs" color={textColor}>
          {goal.goal_name}
        </Box>
        <HStack flex="20" spacing={0} borderColor={bdColor} width="100%" height="32px">
          {cells}
        </HStack>
      </HStack>
    );
  });

export default GoalRow;