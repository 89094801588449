import React, { useState, useContext } from 'react';
import {
  Badge,
  Checkbox,
  HStack,
  VStack,
  Menu,
  MenuButton,
  Flex,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AiFillCaretDown } from 'react-icons/ai';
import { Draggable } from '@hello-pangea/dnd';

import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { FormContext } from '../../../context/FormContext.jsx';

import c from '../../../uc_constants/fwd_constants.js';
import th from '../../../uc_utils/tasks/tasks.js';


import { useTasks } from '../../../data_hooks/elements/useTasks.jsx';


const NewTaskRow = ({ task, index, taskList, showProject=false, ...props}) => {
  const { state, dispatch } = useContext(ForwardContext);
  const toast = useToast();
  const ta = useTasks();
  const { editNewTask } = useContext(FormContext);
  const [isChecked, setIsChecked] = useState(false);

  let isDisabled = false;
  let showEdit = true;
  let showDelay = false;
  let showDelete = false;
  let showReopen = true;
  let showRepeat = true;
  let showAbandon = true;
  let showChangeResolve = false;
  let isStrikethrough = false;
  let background_color;
  let showUnpin = task.is_pinned;
  let showPin = !task.is_pinned;


  let recur_text = 'Repeat...';
  const is_recurring = task.recur_weeks > 0;
  if(is_recurring) {
    recur_text = 'Manage Repitition...';
  }

  if(taskList === 'accomplishments') {
    showEdit = false;
    showDelay = false;
    showDelete = true;
    showReopen = true;
    showRepeat = false;
    showAbandon = false;
    showChangeResolve = true;
  }


  
  background_color = th.get_color(task, state.ui.currentWeek, useColorModeValue('light','dark'));
  const subtext_color = useColorModeValue("gray.700","gray.300");

  const handleCompleteTask = () => {
    setIsChecked(true);
    toast({
      title: 'Task Completed, nice!',
      status: 'success',
      duration: 1000,
      isClosable: true,
    });
    //setTimeout(() => {
      ta.completeNewTask(task)
    //}, 500); // Adjust the timeout to match your animation duration
    
  }

  const handleEditClick = () => {
    const task_to_send = task;
    task_to_send['taskList'] = taskList;
    let fields_to_show = {
      'name':true,
      'pinned:':true,
      'task_category':true,
    }
    if(task_to_send.item_planned_week !== null) {
      fields_to_show['planned_week'] = true;
    }
    else {
      fields_to_show['task_list_selector'] = true;
    }

    editNewTask(task_to_send, false, fields_to_show)
  }
  const handleRecurClick = () => {
    const recur_options_to_show = {
      'name':true,
      'project':false,
      'task_list_selector':false,
      'pinned':true,
      'recur_weeks':true,
      'task_category':true,
      'planned_week':false,
  }
    const task_data = {...task, taskList: taskList, modal_title: 'Edit Recurrence'}
    editNewTask(task_data,false,recur_options_to_show)
  }

  const handleResolveChangeClick = () => {
    const resolve_options_to_show = {
      'name':true,
      'project':false,
      'task_list_selector':false,
      'pinned':false,
      'recur_weeks':false,
      'planned_week':true,
      'resolve_date':true,
  }
    const task_data = {...task, taskList:'accomplishments', modal_title: 'Change Completion Date'}
    editNewTask(task_data,false,resolve_options_to_show)
  }

  let tooltip_text = task.item_name;
  if(task.recur_weeks > 0) {
    tooltip_text += ' - recurs every ' + task.recur_weeks + ' week' + (task.recur_weeks > 1 ? 's' : '');
  }

  
  return (
    <Draggable draggableId={task.id.toString()} index={index}>
    {(provided) => (
    <HStack spacing={0}
    {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          {...props}
          style={{
            ...provided.draggableProps.style, // Merge draggable styles
            transform: `
              ${provided.draggableProps.style?.transform || ''}
            `, // Combine transforms
            //opacity: isChecked ? 0 : 1,
            //transition: isChecked ? 'all 0.5s ease-out' : undefined, // Apply transition only when needed
          }}
    >
        <Checkbox isDisabled={isDisabled} 
                  borderRadius="0px !important"
                  size={showProject ? 'md' : 'md'}
                  isChecked={task.item_status === 'complete'} 
                  colorScheme={th.get_colorScheme(task, state.ui.currentWeek )}
                  onChange={(e) => handleCompleteTask()} />
        <Menu>
        <Tooltip label={tooltip_text} placement="top" hasArrow>
          <MenuButton borderRadius={0} as={Badge} backgroundColor={background_color} px={0.5} py={0} cursor="pointer" flex="1" overflowX="hidden">
            <Flex width="100%" alignItems="center" justifyContent="space-between">
              <VStack spacing={0} mr={0} ml={0.5} alignItems="flex-start">
              <HStack>
                {task.is_pinned && c.main_icons.pin}
                {task.recur_weeks > 0 && c.main_icons.recurrence}
                <Text fontSize="xs" textDecoration={isStrikethrough} isTruncated maxWidth="350px">{task.item_name} </Text>
              </HStack>
              {showProject && 
              <Text fontSize="3xs" color={subtext_color} isTruncated maxWidth="350px">{th.get_project_name_from_id(task.project_id,state.projects)}
              </Text>}
              </VStack>
              <AiFillCaretDown display="inline" size={12} />
            </Flex>
          </MenuButton>
        </Tooltip>
        <Portal>
          <MenuList zIndex={4}>
            {showEdit && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
            {showRepeat && <MenuItem onClick={() => handleRecurClick()}>{recur_text}</MenuItem>}
              {showPin && <MenuItem onClick={() => ta.pinTask(task, taskList, true)}>Pin</MenuItem>}
              {showUnpin && <MenuItem onClick={() => ta.pinTask(task, taskList, false)}>Unpin</MenuItem>}
              {showAbandon && <MenuItem onClick={() => ta.abandonTask(task, taskList)}>Abandon</MenuItem>}
              {showDelay && <MenuItem onClick={() => ta.delayTask(task,taskList)}>Delay</MenuItem>}
              {showDelete && <MenuItem onClick={() => ta.deleteTask(task,taskList)}>Delete</MenuItem>}
              {showReopen && <MenuItem onClick={() => ta.reopenTask(task,taskList)}>Reopen</MenuItem>}
              {showChangeResolve && <MenuItem onClick={() => handleResolveChangeClick()}>Change Completion Date</MenuItem>}
          </MenuList>
        </Portal>
      </Menu>
      </HStack>
    )}
    </Draggable>
  );
};

export default NewTaskRow;
