import React from 'react';
import { Box, useToast, useColorModeValue, Flex} from '@chakra-ui/react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { format, parse, startOfWeek, getDay, addDays, setHours, setMinutes,  isWithinInterval, set, isBefore, isEqual, endOfDay, startOfDay  } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { FormContext } from '../../../context/FormContext.jsx';
import dhf from '../../../uc_utils/dates/dates.js';
import CustomCalendarEvent from './CustomCalendarEvent.jsx';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { useEvents } from '../../../data_hooks/elements/useEvents.jsx';


const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (date) => startOfWeek(date, { weekStartsOn: 1 }), // Start week on Monday
  getDay,
  locales,
});


const parseTime = (timeString, baseDate) => {
    if (!timeString) return null;
    const [hours, minutes] = timeString.split(':').map(Number);
    return set(baseDate, { hours, minutes, seconds: 0, milliseconds: 0 });
  };
  
  const isWithinWorkOrSleepTime = (date, userObject) => {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const currentTime = set(currentDate, { seconds: 0, milliseconds: 0 });
  
    // Work time check
    const isWorkDay = userObject.work_time.days[dayOfWeek === 0 ? 6 : dayOfWeek - 1] === 1;
    let isWork = false;
    if (isWorkDay) {
      const workStart = parse(userObject.work_time.start, 'HH:mm', currentDate);
      const workEnd = parse(userObject.work_time.end, 'HH:mm', currentDate);
      isWork = isWithinInterval(currentTime, { start: workStart, end: workEnd });
    }
  
    // Sleep time check
    let sleepStart = parse(userObject.sleep_time.start, 'HH:mm', currentDate);
    let sleepEnd = parse(userObject.sleep_time.end, 'HH:mm', currentDate);
    
    let isSleep;
    if (isBefore(sleepEnd, sleepStart)) {
      // Sleep time crosses midnight
      if (isBefore(currentTime, sleepEnd)) {
        // Current time is after midnight but before wake-up time
        sleepStart = addDays(sleepStart, -1);
      } else {
        // Current time is after wake-up time but before next sleep time
        sleepEnd = addDays(sleepEnd, 1);
      }
      isSleep = isWithinInterval(currentTime, { start: sleepStart, end: sleepEnd });
    } else {
      // Sleep time doesn't cross midnight
      isSleep = isWithinInterval(currentTime, { start: sleepStart, end: sleepEnd });
    }
  
    return { isWork, isSleep };
  };

const DnDCalendar = withDragAndDrop(Calendar);

const NewEventCell = ({ period, length, itemData, dayHeight = length <= 14 ? '500px' : '500px' }) => {
    const { state } = React.useContext(ForwardContext);
    const { addEvent, editEvent } = React.useContext(FormContext);
    const { upsertEventFull } = useEvents();
    const toast = useToast();

    const workCellBg = useColorModeValue('var(--chakra-colors-orange-100)', 'var(--chakra-colors-forwardOrange-200)');
    const sleepCellBg = useColorModeValue('var(--chakra-colors-gray-100)', 'var(--chakra-colors-gray-600)');
    const todayBg = useColorModeValue('var(--chakra-colors-forwardGold-50)', 'var(--chakra-colors-forwardGold-500)');
    const todayworkBg = useColorModeValue('var(--chakra-colors-orange-200)', 'var(--chakra-colors-forwardOrange-400)');
    const todaysleepBg = useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-gray-700)');
    const baseBg = useColorModeValue('var(--chakra-colors-forwardWhite-100)', 'var(--chakra-colors-forwardBlue-800)');

    const CustomDateCellWrapper = ({ children, value }) => {
      const isToday = value.toDateString() === new Date().toDateString();
      return React.cloneElement(React.Children.only(children), {
        style: {
          ...children.style,
          backgroundColor: isToday ? todayBg : baseBg,
        },
      });
    };

    // Custom Day Header for coloring today's header in week view
    const CustomDayHeader = ({ label, date }) => {
      const isToday = date && date.toDateString() === new Date().toDateString();
    
      return (
        <Flex
          width="100%"
          height="100%" // Ensure the Flex component takes full height
          alignItems="center"
          justifyContent="center" // Center the content horizontally and vertically
          textAlign="center"
          fontSize="sm"
          fontWeight={isToday ? 'bold' : 'normal'}
          p={1}
          style={{
            backgroundColor: isToday ? todayBg : baseBg,
            fontWeight: isToday ? 'bold' : 'normal',
            height: '100%', // Set full height
            width: '100%',  // Set full width
          }}
        >
          {label}
        </Flex>
      );
    };
    
    async function straight_event_edit({event, startDate, startTime, endDate, endTime}) {
      let event_data = { ...event
                        }
      //Build start and end times:
      //TODO
      event_data["start_time"] = dhf.recombineDateTime(startDate, startTime);
      event_data["end_time"] = dhf.recombineDateTime(endDate, endTime);
  
      console.log(event_data);

      let {result, message} = await upsertEventFull({event_data});

      console.log(result);
      if(result === 'error') {
      }
      else {
        //onClose();
        /*toast({
          title: 'Event Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });*/
      }
  
    };



    const userObject = state?.account?.user_config || {};
    //console.log('UserObject:', userObject);

    if(!period || !itemData || !period.start || !period.end) {
        return (<Box>Loading</Box>)
    }

const slotPropGetter = (date) => {
  const { isWork, isSleep } = isWithinWorkOrSleepTime(date, userObject);
  const isToday = new Date(date).toDateString() === new Date().toDateString();
  //console.log('Date:', format(date, 'yyyy-MM-dd HH:mm'), 'IsWork:', isWork, 'IsSleep:', isSleep);
  
  let style = {};
  if (isToday) {
    if(isWork) {
      style.backgroundColor = todayworkBg;
    }
    else if(isSleep) {
      style.backgroundColor = todaysleepBg;
    }
    else {
    style.backgroundColor = todayBg; // Yellow
    }
  } else {
  
  if (isWork) {
    style.backgroundColor = workCellBg; // Green
  } else if (isSleep) {
    style.backgroundColor = sleepCellBg; // Black with opacity
  }
  else {
    style.backgroundColor = baseBg;
  }
}

  style['maxHeight']='10px !important';

  return { style };
};

    const display_mode = length <= 1 ? 'day' : length <= 8 ? 'week' : length <= 31 ? 'month' : 'year';

    const events = itemData.map(event => ({
        id: event.id,
        title: event.event_name,
        start: new Date(event.start_time),
        end: new Date(event.end_time),
        allDay: false,
        resource: event,
    }));

    const handleSelectSlot = ({ start, end }) => {
        const editorData = {
            start_date: format(start, 'yyyy-MM-dd'),
            start_time: format(start, 'HH:mm'),
            end_date: format(end, 'yyyy-MM-dd'),
            end_time: format(end, 'HH:mm'),
        };
        addEvent(editorData);
    };

    const handleSelectEvent = (event) => {
        const { resource } = event;
        let editorData = { ...resource };
        editorData.start_date = format(new Date(resource.start_time), 'yyyy-MM-dd');
        editorData.start_time = format(new Date(resource.start_time), 'HH:mm');
        editorData.end_date = format(new Date(resource.end_time), 'yyyy-MM-dd');
        editorData.end_time = format(new Date(resource.end_time), 'HH:mm');
        editEvent(editorData);
    };

    const handleEventChange = ({ event, start, end }) => {
        const updatedEvent = {
            ...event.resource,
            start_time: start.toISOString(),
            end_time: end.toISOString(),
            start_date: format(start, 'yyyy-MM-dd'),
            start_time: format(start, 'HH:mm'),
            end_date: format(end, 'yyyy-MM-dd'),
            end_time: format(end, 'HH:mm'),
        };
        //editEvent(updatedEvent);
        //Straight update the event:
        straight_event_edit({event: event.resource, 
                            startDate: format(start, 'yyyy-MM-dd'), 
                            startTime: format(start, 'HH:mm'), 
                            endDate: format(end, 'yyyy-MM-dd'), 
                            endTime: format(end, 'HH:mm')});
    };

    // Calculate the correct date range based on the period and length
    const startDate = new Date(period.start);
    const endDate = new Date(period.end);

    // Set the time range to display
    //Convert values from parse(userObject.sleep_time.start, 'HH:mm', currentDate)
    //const minTime = setMinutes(setHours(new Date(), 0), 0);  // 6:00 AM
    const minTime = setMinutes(parseTime(userObject.sleep_time.end, new Date()),0)
    //console.log(minTime);
    //const maxTime = setMinutes(setHours(new Date(), 23), 59); // 10:00 PM
    const maxTime = setMinutes(parseTime(userObject.sleep_time.start, new Date()),0)

    return (
        <Box borderWidth="1px" borderColor="forwardBlue.800" width="100%">
            <DnDCalendar
                localizer={localizer}
                events={events}
                components={{
                    event: CustomCalendarEvent,
                    dateCellWrapper: CustomDateCellWrapper,
                    //resourceHeader: CustomDayHeader,
                    week: {header: CustomDayHeader},
                }}
                startAccessor="start"
                endAccessor="end"
                style={length <= 14 ? { height: '100%' } : { height: dayHeight }}
                //style={{ height: {length <= 14 ? "100%" : dayHeight} }}
                view={display_mode}
                onView={ (view) => console.log(view) }
                views={['day', 'week', 'month']}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                onEventDrop={handleEventChange}
                onEventResize={handleEventChange}
                selectable
                date={startDate}
                onNavigate={(date, view) => console.log(date, view)}
                length={length}
                toolbar={false}
                formats={{
                    timeGutterFormat: (date, culture, localizer) =>
                        localizer.format(date, 'HH:mm', culture),
                }}
                min={minTime}
                max={maxTime}
                step={30}
                timeslots={2}
                slotPropGetter={slotPropGetter}
                eventPropGetter={(event, start, end, isSelected) => {
                    let newStyle = {
                        backgroundColor: 'teal',
                        color: 'white',
                        borderColor: 'var(--chakra-colors-teal-700)',
                        boxShadow: 'sm',

                    };

                    if (new Date(event.resource.end_time) < new Date()) {
                        newStyle.backgroundColor = 'gray';
                        newStyle.borderColor = 'var(--chakra-colors-gray-700)';
                    }

                    return {
                        className: '',
                        style: newStyle,
                    };
                }}
            />
        </Box>
    );
};

export default NewEventCell;