import { supabase } from '../uc_constants/supabaseClient';
import { format } from 'date-fns';

export async function upsertHabit(habit_data) {
    //console.log(habit_data);
    let { data, error } = await supabase
    .from('forward_habits')
    .upsert(habit_data , 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}

export async function upsertHabit_base(habit_data) {
  // If area_id is the string "null", replace it with actual null
  if (habit_data.area_id === "null") {
    habit_data.area_id = null;
  }
  
  let { data, error } = await supabase
  .from('forward_habits')
  .upsert(habit_data, 
          {onConflict: 'id' })
    .select('*');

if (error) {
    console.error('Error upserting user:', error);
}
return data;
}

export async function readHabits(userId) {
    let { data, error } = await supabase
        .from('forward_habits')
        .select('*')
        .eq('user_id', userId)
        .filter('date_deleted','is','null')
        .order('order_id');
    if (error) throw error;

    
    // Organize projects by area
    const habitsByArea = {};
    data.forEach((habit) => {
      habit.values = {'2023-07-01':1,'2023-06-30':2}
      if (!habitsByArea[habit.area_id]) {
        habitsByArea[habit.area_id] = [];
      }
      habitsByArea[habit.area_id].push(habit);
    });
  
    return habitsByArea;

}

export async function readHabits_all(userId, history_start) {
  //console.log(history_start);
  let { data, error } = await supabase
      .from('forward_habits')
      .select(`
          *,
          forward_habit_values (
              habit_id, date, value
          ),
          forward_targets (
            habit_id, date, target_value, is_greater_than
          )
      `)
      .filter('date_deleted','is','null')
      .order('order_id');

  if (error) throw error;

  // Organize habits by area
  const habitsByArea = {};
  data.forEach((habit) => {
    if (!habitsByArea[habit.area_id]) {
      habitsByArea[habit.area_id] = [];
    }

    // Format habit values as a dictionary
    const values = {};
    habit.forward_habit_values
      .filter((value) => new Date(value.date) >= new Date(history_start)) // Filter values based on history_start date
      .forEach((value) => {
        values[value.date] = value.value;
      });

    // Format targets as an array
    // Assign targets directly to the habit object
    habit.targets = habit.forward_targets;
    delete habit.forward_targets;

    // Replace the forward_habit_values array with the new dictionary
    habit.values = values;
    delete habit.forward_habit_values;

    habitsByArea[habit.area_id].push(habit);
  });

  return habitsByArea;
}
