import React from 'react';
import { Box, Flex, Text, VStack, Tooltip, HStack, useMediaQuery, useColorModeValue } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import { generateCells, generatePeriods } from '../helpers/cells';
import dhf from '../../../uc_utils/dates/dates.js';
import EventCell from '../cells/EventCell.jsx';
import NewEventCell from '../cells/NewEventCell.jsx';
import { differenceInDays } from 'date-fns';

const EventsRow = ({ ...props }) => {
    const { state } = React.useContext(ForwardContext);
    const [isMobile] = useMediaQuery("(max-width: 600px)");
    const bgColor = useColorModeValue("forwardWhite.100", "forwardBlue.700");
    const textColor = useColorModeValue("gray.800", "gray.200");


    const my_events = state?.events || [];
    //const periods = generatePeriods(dhf.getLocalMidnightDate(state.grid_ui.start_date), dhf.getLocalMidnightDate(state.grid_ui.end_date), );
    //const length = differenceInDays(dhf.getLocalMidnightDate(state.grid_ui.start_date), dhf.getLocalMidnightDate(state.grid_ui.end_date)) + 1;
    //console.log(periods);
    let period = {}
    let length = 0
    //console.log(state.grid_ui.selected_level)
    if(state.grid_ui.selected_level === 'days' || state.grid_ui.selected_level === 'weeks' || state.grid_ui.selected_level === 'months') {
        //console.log('GridUI: ',state.grid_ui);
        period = {
            start: state.grid_ui.start_date,
            end: state.grid_ui.end_date,
            fullStart: state.grid_ui.start_date,
            fullEnd: state.grid_ui.end_date,
        }
        length = differenceInDays(state.grid_ui.end_date, dhf.getMidnightDate(state.grid_ui.start_date)) + 1;

        //console.log('Period:', period)
        //console.log('Length:', length)
    }


    return (
        <HStack width="100%" bg={bgColor} borderRadius="md" py={0} mb={2} >
            {!isMobile && <Box flex="2" minWidth="145px" maxWidth="145px" textAlign="left" fontSize="2xs" ml={2} color={textColor}>Events</Box>}
            <HStack alignItems="stretch" flex="20" spacing={0} width="100%" justifyContent="stretch" borderRight="1px solid" borderColor="forwardBLue.800">
            {(state.grid_ui.selected_level === 'days' || state.grid_ui.selected_level === 'weeks' || state.grid_ui.selected_level === 'months') 
            && <NewEventCell period={period} length={length} itemData={my_events} />}
            {(state.grid_ui.selected_level === 'quarters' || state.grid_ui.selected_level === 'years') 
            && generateCells(state.grid_ui.start_date, state.grid_ui.end_date, state.grid_ui.visible_base_level, EventCell, my_events)}
            </HStack>
        </HStack>
    )
}


export default EventsRow;