import { useEffect, useContext, useCallback } from 'react';
import { ForwardContext } from '../../context/ForwardContext.jsx';
import { usePermissions } from '../permissions/usePermissions.jsx';
import { useToast } from '@chakra-ui/react';
import { upsertNewGoal, upsertNewGoalEntry } from '../../database_api/newGoalService.js';
import dhf from '../../uc_utils/dates/dates.js';
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';
import { id } from 'date-fns/locale';

export const useNewGoals = () => {
    const { state, dispatch, session } = useContext(ForwardContext);
    const session_id = state.session_id;
    const {checkPermissionToAdd} = usePermissions();
    const toast = useToast();

    const upsertNewGoalFull = async ({goal_data}) => {
        //Submit to Database
        let mode = 'create';
        if(goal_data.id > 0) {
          mode = 'edit';
        }
        let permission_to_create = checkPermissionToAdd('new_goals',mode);
        
        try {
          if(permission_to_create) {
        const new_goal = await upsertNewGoal(goal_data);
        console.log(new_goal);
        trackCustomEvent('FWD New Goal Added', {goal_name: goal_data.goal_name});
        if (new_goal) {
    
          dispatch({ type: 'UPSERT_NEW_GOAL', payload: new_goal });
          console.log('Goal added/updated!');
          if(mode === 'edit' > 0) {
            toast.closeAll(); // Close all existing toasts
            toast(  {   
                title: "Goal Updated!",
                description: "Your goal has been updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                }
            )
            return {result:'success',message:'goal edit successful'}
          }
          else {
            toast.closeAll(); // Close all existing toasts
            toast(  {   
                title: "Goal Added!",
                description: "Your goal has been added successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                }
            )
            return {result:'success',message:'goal add successful'}
            
          }
      //If not, alert to error
      } else  {
          alert("Error Adding Goal!")
          return {result:'error',message:'goal add failure at database level'}
      }
      }
        else {
          return {result:'error',message:'goal limit reached.'}
        }
      }
      catch {
        return {result:'error',message:'goal add failed.'}
      }
    }

    const upsertNewGoalEntryFull = async ({goal_entry_data}) => {
        let mode = 'create';
        const area_id = goal_entry_data.area_id;
        if(goal_entry_data.id > 0) {
          mode = 'edit';
        }
        let permission_to_create = true;
        console.log(goal_entry_data);
        
        try {
          if(permission_to_create) {
            console.log('in try to create data');

        const dataToSend = goal_entry_data;
        console.log('Manipulating Data to Send');
        delete dataToSend['area_id'];
        if(goal_entry_data.id < 0) {
            delete dataToSend['id'];
        }
        console.log(dataToSend);
        let new_goal_entry = await upsertNewGoalEntry(dataToSend);
        console.log(new_goal_entry);
        trackCustomEvent('FWD New Goal Entry Added');
        if (new_goal_entry) {
            const upsert_data = {...goal_entry_data, area_id:area_id, id:new_goal_entry[0].id};
            console.log(upsert_data);
          dispatch({ type: 'UPSERT_NEW_GOAL_ENTRY', payload: [upsert_data] });
          console.log('Goal entry added/updated!');
          if(mode === 'edit' > 0) {
            toast.closeAll(); // Close all existing toasts
            toast(  {   
                title: "Goal Entry Updated!",
                description: "Your goal has been updated successfully.",
                status: "success",
                duration: 1000,
                isClosable: true,
                }
            )
            return {result:'success',message:'goal edit successful'}
          }
          else {
            toast.closeAll(); // Close all existing toasts
            toast(  {   
                title: "Goal Entry Added!",
                description: "Your goal has been added successfully.",
                status: "success",
                duration: 1000,
                isClosable: true,
                }
            )
            return {result:'success',message:'goal add successful'}
            
          }
      //If not, alert to error
      } else  {
          alert("Error Adding Goal!")
          return {result:'error',message:'goal add failure at database level'}
      }
      }
        else {
          return {result:'error',message:'goal limit reached.'}
        }
      }
      catch {
        return {result:'error',message:'goal add failed.'}
      }
    }
    
  
  
    return {upsertNewGoalFull, upsertNewGoalEntryFull};
};
