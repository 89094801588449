import React, { createContext, useReducer, useEffect, useRef, useState } from 'react';
import forwardReducer from './forwardReducer';
import { supabase } from '../uc_constants/supabaseClient.js';
import dhf from '../uc_utils/dates/dates.js'

import useRealtimeTasks from '../data_hooks/realtime/useRealtimeTasks.jsx';
import { readCurrentUser } from '../database_api/accountService.js';
import { v4 as uuidv4 } from 'uuid';

const today =  dhf.formatDate(dhf.getStartOfWeek(new Date()))
const eoweek = dhf.formatDate(dhf.addDaysToDate(dhf.getStartOfWeek(new Date()), 6))

const initialState = {
  session_id: uuidv4(),
  ui: {
    currentView: "Home",
    currentForm: "lifeArea",
    currentWeek: dhf.getStartOfWeek(new Date()),
    currentYear: parseInt(new Date().getFullYear()),
    editorData: {},
    optionsToShow: {},
    reminderDays: 31,
    readOnly: true,
    thisWeek: dhf.getStartOfWeek(new Date()),
    nextWeek: dhf.getStartOfWeek(dhf.addDaysToDate(new Date(), 7)),
    projectView: null,
    goalView: null,
  },
  grid_ui: {
    start_date: dhf.getLocalMidnightDate(today),
    end_date: dhf.getLocalLastSecondDate(eoweek),
    selected_level: 'weeks',
    visible_levels: ['years', 'quarters', 'months', 'weeks'],
    visible_base_level: 'days',
    show_all_tasks: false,
    fetched_task_ranges: [],
  },
  account: {},
  areas: [],
  habits: [],
  projects: [],
  goals: [],
  new_goals: [],
  events: [],
  reminders: [],
  tasks: [],
  new_tasks: {
    tasks_by_date: {},
    task_id_to_date: {},
  },
  shares: [],
  live_views: {
    project_id: -1,
    project_history: [],
    area_id: -1,
    area_history: [],
  },
  stats: {}
  // Add more initial state properties as needed
};

const TaskRealtimeListener = ({ dispatch, stateRef }) => {
  useRealtimeTasks(dispatch, stateRef);
  return null; // This component doesn't need to render anything
};

//const ForwardContext = createContext(initialState);
const ForwardContext = createContext();


/* Main context provider */
const ForwardProvider = ({ children }) => {

  const [state, dispatch] = useReducer(forwardReducer, initialState);
  const stateRef = useRef(state);
  const [session, setSession] = useState(null); // New session state
  const [loadingSession, setLoadingSession] = useState(true); // New loading state

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    // Handling session similarly to IntellectInboxProvider
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoadingSession(false);
    });
    const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoadingSession(false);
    });

  }, []);

  useEffect(() => {
    if (session && session.user) {
      // Fetch and update account information in the context state
      // This might involve dispatching an action to your reducer
      const fetchAccountData = async () => {
        // Fetch user data logic here
        //console.log('Fetching Account');
        try {
          const accountData = await readCurrentUser();
          //console.log(accountData);

          dispatch({ type: 'SET_ACCOUNT', payload: accountData });
          //console.log(habitsData);
        } catch (error) {
          console.error('Failed to fetch account:', error);
        }

      };
      fetchAccountData();


    }
  }, [session]);

  return (
    <ForwardContext.Provider value={{ state, dispatch }}>
      <TaskRealtimeListener dispatch={dispatch} stateRef={stateRef} />
      {children}
    </ForwardContext.Provider>
  );
};

export { ForwardContext, ForwardProvider };
