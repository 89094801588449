import { useEffect, useContext, useCallback } from 'react';
import { ForwardContext } from '../../context/ForwardContext';
import { readCurrentUser, createUser as dbCreateUser } from '../../database_api/accountService';
import { upsertUserProfile } from '../../database_api/accountService';

export const useAccounts = () => {
  const { dispatch, session } = useContext(ForwardContext);
  /****************************************/
  //Main Sync Function to Grab all the data
  /****************************************/
  const syncAccount = useCallback(async () => {
    //console.log('Fetching Account');
    try {
        const accountData = await readCurrentUser();
        //console.log(accountData);

        dispatch({ type: 'SET_ACCOUNT', payload: accountData });
        //console.log(habitsData);
      } catch (error) {
        console.error('Failed to fetch account:', error);
      }

  }, [dispatch, session]);

  const setAccountFlag = async (user, property, value) => {

      const new_data = {'id':user.id, [property]:value  }
      //Submit to Database
      const new_user = await upsertUserProfile(new_data)
      //Update State
      //SET_USER_PROPERTY
      dispatch({ type: 'SET_USER_PROPERTY', payload: { 'property':property,'value':value } });

      
    //If not, alert to error
     if (!new_user)  {
        alert("Error Updating Goal!")
      }
      console.log('Upser Profile added/updated!');
    }

  const createUser = async (user) => {
    try {
      const newUser = await dbCreateUser(user);
      if (newUser) {
        //dispatch({ type: 'SET_ACCOUNT', payload: newUser });
        return newUser;
      }
    } catch (error) {
      console.error('Failed to create account:', error);
    }
  }


  return {syncAccount, setAccountFlag, createUser};
};