import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Flex, Text, VStack, Tooltip, HStack, useMediaQuery, useColorModeValue} from '@chakra-ui/react';
import { format, differenceInDays } from 'date-fns';
import { generatePeriods, startOfMondayWeek, setMidnight } from './helpers/cells';
import dhf from '../../uc_utils/dates/dates.js' 
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { ForwardContext } from '../../context/ForwardContext';

const LEVELS = ['years', 'quarters', 'months', 'weeks', 'days'];
const MIN_CELL_WIDTH = 30; // minimum width in pixels for a cell to be displayed

const CalendarBar = ({ startDate, endDate, startingLevel, onCellClick, ...props }) => {
  const [data, setData] = useState({});
  const {state, dispatch} = React.useContext(ForwardContext);
  const [selectedLevel, setSelectedLevel] = useState(startingLevel);
  const visibleLevels = state?.grid_ui?.visible_levels;
  const containerRef = useRef(null);
  const [isMobile] = useMediaQuery("(max-width: 600px)");
  const mainBg = useColorModeValue("forwardWhite.100", "forwardBlue.700");
  const bdColor = useColorModeValue("gray.200", "gray.700");
  const selectedBg = useColorModeValue("forwardBlue.200", "forwardBlue.500");
  const hoverBg = useColorModeValue("gray.300", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.200");

  useEffect(() => {
    //start should equal the datetime of the day passed as startDate, right at 00:00:00
    if(!startDate || !endDate) return;
    const start_string = dhf.formatDate(startDate)
    const  end_string = dhf.formatDate(endDate)
    const start = dhf.getLocalMidnightDate(start_string);
    //end should equal the datetime of the day passed as endDate, right at 23:59:59
    const end = dhf.getLocalLastSecondDate(end_string);
    //console.log(`Start: ${start} End: ${end}`);

    const newData = LEVELS.reduce((acc, level) => {
      acc[level] = generatePeriods(start, end, level);
      return acc;
    }, {});

    setData(newData);
  }, [startDate, endDate]);

  const handleCellClick = useCallback((level, item, button) => {
    setSelectedLevel(level);
    //console.log(item);
    
    // Update visible levels immediately
    const updatedVisibleLevels = LEVELS.slice(LEVELS.indexOf(level), LEVELS.indexOf(level) + 2);
    //setVisibleLevels(updatedVisibleLevels);

    let startPoint = item.fullStart;
    let endPoint = dhf.getLocalLastSecondDate(dhf.formatDate(item.fullEnd));
    if(button === "prev"){
      const {start, end} = dhf.getPnPeriod(item.fullStart, level, 'prev');
      startPoint = start;
      endPoint = dhf.getLocalLastSecondDate(dhf.formatDate(end));
    }
    else if(button === "next"){
      const {start, end} = dhf.getPnPeriod(item.fullStart, level, 'next');
      startPoint = start;
      endPoint = dhf.getLocalLastSecondDate(dhf.formatDate(end));
    }

    dispatch({
      type: 'SET_GRIDUI_SELECTED_LEVEL',
      payload: level
    })

    // Call onCellClick with updated state
    onCellClick(level, updatedVisibleLevels, {
      start: startPoint,
      end: endPoint,
    });
  }, [onCellClick]);

  /*useEffect(() => {
    // Force re-render of child components when visible levels change
    props.onVisibleLevelsChange?.(visibleLevels);
  }, [visibleLevels, props]);*/

  const renderLevel = (level) => {
    const items = data[level] || [];
    const totalDays = differenceInDays(new Date(endDate), new Date(startDate)) + 1;

    return (
      <Flex position="relative" height="24px" key={level}>
        {items.map((item, index) => {
          const startOffset = differenceInDays(item.visibleStart, new Date(startDate));
          const itemDays = differenceInDays(item.visibleEnd, item.visibleStart) + 1;
          const style = {
            left: `${(startOffset / totalDays) * 100}%`,
            width: `${(itemDays / totalDays) * 100}%`,
          };

          return (
            <Tooltip key={index} label={`${format(item.visibleStart, 'MMM d, yyyy')} - ${format(item.visibleEnd, 'MMM d, yyyy')}`} placement="top">
              <Box
                height="100%"
                borderRight="1px solid"
                borderColor={bdColor}
                cursor="pointer"
                position={selectedLevel === level ? 'sticky' : 'absolute'}
                zIndex={selectedLevel === level ? 1010 : 0}
                bg={selectedLevel === level ? selectedBg : mainBg}
                style={style}
                p={0}
              >
                
                {(level === selectedLevel || dhf.isLevelLarger(level,selectedLevel)) && (
                  <HStack width="100%" justifyContent="stretch">
                    <HStack spacing={0.5} p={1} _hover={{ bg: hoverBg }} onClick={() => handleCellClick(level, item, "prev")}>
                  <AiOutlineArrowLeft size="16px" />
                  <Text fontSize="xs"></Text>
                  </HStack>
                  <Text flex="1" fontSize="md" textAlign="center" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" 
                  _hover={{ bg: hoverBg }} 
                  onClick={() => handleCellClick(level, item, "this")}>
                  {formatDate(item.visibleStart, level)}
                  </Text>
                    <HStack spacing={0.5} p={1} _hover={{ bg: hoverBg }} onClick={() => handleCellClick(level, item, "next")}>
                  <Text fontSize="xs"></Text>
                  <AiOutlineArrowRight size="16px" />
                  </HStack>
                  </HStack>
                )}
                {level !== selectedLevel && (
                <Text fontSize="md" textAlign="center" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" 
                _hover={{ bg: hoverBg }}
                onClick={() => handleCellClick(level, item, "this")}
                >
                  {formatDate(item.visibleStart, level)}
                </Text>
                )}
                
              </Box>
            </Tooltip>
          );
        })}
      </Flex>
    );
  };

  const formatDate = (date, level) => {
    switch (level) {
      case 'years': return format(date, 'yyyy');
      case 'quarters': return `Q${Math.floor(date.getMonth() / 3) + 1}`;
      case 'months': return format(date, 'MMM');
      case 'weeks': return `W${format(startOfMondayWeek(date), 'w')}`;
      case 'days': return format(date, 'd');
      default: return '';
    }
  };

  //console.log(visibleLevels);

  return (
    <HStack {...props} >
      {!isMobile && <>
        <Box flex="1" minWidth="100px" textAlign="left" fontSize="xs" color={textColor}>Life Area</Box>
        <Box flex="1" minWidth="100px" textAlign="left" fontSize="xs" color={textColor}>Goals</Box>
        </>}
      <VStack ref={containerRef} flex="20" align="stretch" spacing={0} borderWidth={1} borderColor="gray.200" width="100%">
        {visibleLevels.map(level => renderLevel(level))}
      </VStack>
    </HStack>
  );
};

export default CalendarBar;