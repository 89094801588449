import React, {useState} from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Box,
  IconButton,
  useDisclosure,
  Text,
  VStack,
  HStack,
  Input,
  Button,
} from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillMinusCircle } from 'react-icons/ai';
import { trackCustomEvent } from '../../../general/functions/plausibleEvent';
import { useNewGoals } from '../../../data_hooks/elements/useNewGoals';
const NewGoalEntryForm = ({ existingEntryId, goalId, areaId, userId, goalName, startDate, endDate, textValue, statusValue, isDisabled, onClose, ...props }) => {
  const [textGoal, setTextGoal] = useState(textValue || '');
    const [statusGoal, setStatusGoal] = useState(statusValue);
    const { upsertNewGoalEntryFull } = useNewGoals();
    //console.log("NewGoalEntryForm: ", existingEntryId, goalId, areaId, userId, goalName, startDate, endDate, textValue, statusValue, isDisabled, onClose);

  const handleSubmit = (newStatus) => {
    //Get Values to submit to database
    let statusToSend = newStatus;
    if(newStatus === -999) {
        statusToSend = statusGoal;
    }
    else {
        setStatusGoal(newStatus);
    }

    const idToSend = existingEntryId ? existingEntryId : -1;
    const submissionData = {
        id: idToSend,
        goal_id: goalId,
        area_id: areaId,
        period_date: endDate,
        goal_list: [textGoal],
        goal_status: statusToSend,

    }
    console.log(submissionData);

    const {result, message} = upsertNewGoalEntryFull({goal_entry_data:submissionData});
    console.log(result, message);

    //Close the form
    onClose();
  };
 
return (
    <PopoverContent borderRadius={0}>
        <PopoverArrow />
        <PopoverBody display="flex" justifyContent="center">
            <VStack spacing={2} width="100%">
                <Text>{goalName}</Text>
                <Text>
                    {startDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}{' '}
                    to{' '}
                    {endDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}
                </Text>
                <Input
                    placeholder="Period Goal"
                    value={textGoal}
                    onChange={(e) => setTextGoal(e.target.value)}
                />
                <HStack width="100%" justifyContent="stretch">
                    <IconButton
                        icon={<AiFillCheckCircle size="25px" />}
                        flex="1"
                        colorScheme="green"
                        onClick={() => handleSubmit(10)}
                        aria-label="Yes"
                        mr={2}
                        width="100%"
                        alignItems="center"
                        borderRadius={0}
                    />
                    <IconButton
                        icon={<AiFillCheckCircle size="25px" />}
                        flex="1"
                        colorScheme="yellow"
                        onClick={() => handleSubmit(5)}
                        aria-label="Skip"
                        width="100%"
                        alignItems="center"
                        borderRadius={0}
                    />
                    <IconButton
                        icon={<AiFillCloseCircle size="25px" />}
                        flex="1"
                        colorScheme="red"
                        onClick={() => handleSubmit(-10)}
                        aria-label="No"
                        mr={2}
                        width="100%"
                        alignItems="center"
                        borderRadius={0}
                    />
                    <IconButton
                        icon={<AiFillMinusCircle size="25px" />}
                        flex="1"
                        colorScheme="gray"
                        onClick={() => handleSubmit(-1)}
                        aria-label="Skip"
                        width="100%"
                        alignItems="center"
                        borderRadius={0}
                    />
                    
                </HStack>
                <Button onClick={() => handleSubmit(-999)}>Submit</Button>
            </VStack>
        </PopoverBody>
    </PopoverContent>
);
};

export default NewGoalEntryForm;