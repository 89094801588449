import { supabase } from '../uc_constants/supabaseClient';

export async function upsertNewGoal(goal_data) {
    let { data, error } = await supabase
    .from('forward_new_goals')
    .upsert(goal_data , 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  return data;
}

export async function upsertNewGoalEntry(goal_entry_data) {
    let { data, error } = await supabase
    .from('forward_new_goal_entries')
    .upsert(goal_entry_data , 
            {onConflict: 'id' })
      .select('*');

  if (error) {
      console.error('Error upserting user:', error);
  }
  //console.log(data);
  //console.log(error);
  return data;
}

export async function readNewGoals(userId) {
    let { data, error } = await supabase
        .from('forward_new_goals')
        .select(`*,
                forward_new_goal_entries:forward_new_goal_entries(*)
                `)
        .lte('goal_status',3)
        .filter('date_deleted','is','null')
        .order('order_id')
        ;
    if (error) {
        console.error('Error fetching goals:', error);
    }

    const goalsByArea = {};
    data.forEach((goal) => {
        if (!goalsByArea[goal.area_id]) {
            goalsByArea[goal.area_id] = [];
          }
          goalsByArea[goal.area_id].push(goal);
    });
    if (error) throw error;
    //console.log(goalsByArea);
    return goalsByArea;
}
