import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import {
  VStack, HStack, Box, Flex, useMediaQuery, useColorModeValue
} from '@chakra-ui/react';

import CalendarBar from '../../fwd_ui/newhome/CalendarBar';
import { ForwardContext } from '../../context/ForwardContext';
import LifeAreaRow from '../../fwd_ui/newhome/rows/LifeAreaRow';
import EventsRow from '../../fwd_ui/newhome/rows/EventsRow';
import TasksRow from '../../fwd_ui/newhome/rows/TasksRow';
import TimelineRow from '../../fwd_ui/newhome/rows/TimelineRow';
import NewGoalCreate from '../../fwd_ui/newhome/forms/NewGoalCreate';
import { useTasks } from '../../data_hooks/elements/useTasks';
import RemindersRow from '../../fwd_ui/newhome/rows/RemindersRow';
import dhf from '../../uc_utils/dates/dates';

const NewHomeView = ({ session, ...props }) => {
    const { state, dispatch } = useContext(ForwardContext);
    const [loading, setLoading] = React.useState(true);
    const [isMobile] = useMediaQuery("(max-width: 600px)");
    const calendarBarBg = useColorModeValue("forwardWhite.200", "forwardBlue.800");

    useEffect(() => {
        // Check if the user has any life areas
        const userHasLifeAreas = state.areas && state.areas.length > 0;
        setLoading(!userHasLifeAreas);
      }, [state.areas]);
    

    const ut=useTasks();

    const task_refresh = useCallback(async () => {
        const { start_date, end_date } = state.grid_ui;
        const new_start_date = new Date(start_date).toISOString().split('T')[0];
        const new_end_date = new Date(end_date).toISOString().split('T')[0];
    
        // Function to check if a date is within any fetched range
        const isDateFetched = (date) => {
            if(!state.grid_ui.fetched_task_ranges) return false;
            if(state.grid_ui.fetched_task_ranges.length === 0) return false;
            return state.grid_ui.fetched_task_ranges.some(range => 
                date >= range.start && date <= range.end
            );
        };
    
        // Determine which parts of the range need to be fetched
        let rangesToFetch = [];
        let currentDate = new Date(new_start_date);
        const endDate = new Date(new_end_date);
    
        while (currentDate <= endDate) {
            if (!isDateFetched(currentDate.toISOString().split('T')[0])) {
                const rangeStart = new Date(currentDate);
                while (currentDate <= endDate && !isDateFetched(currentDate.toISOString().split('T')[0])) {
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                const rangeEnd = new Date(currentDate);
                rangeEnd.setDate(rangeEnd.getDate() - 1);
                rangesToFetch.push({
                    start: rangeStart.toISOString().split('T')[0],
                    end: rangeEnd.toISOString().split('T')[0]
                });
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
    
        // Fetch data for each unfetched range
        for (const range of rangesToFetch) {
            //console.log(`Loading tasks for ${range.start} to ${range.end}`);
            await ut.syncTasksByDateRange(range.start, range.end);
            await ut.syncNewBacklog();
            //setFetchedRanges(prev => [...prev, range]);
            dispatch({type:'SET_FETCHED_TASK_RANGES', payload: [...state.grid_ui.fetched_task_ranges, range]});
        }
    }, [state.grid_ui.start_date, state.grid_ui.end_date, state.grid_ui.fetched_task_ranges]);
    
    useEffect(() => {
        task_refresh();
    }, [task_refresh]);

    useEffect(() => {
        if (isMobile) {
            if (state.grid_ui.selected_level === 'years') {
                dispatch({
                  type: 'SET_GRIDUI_VISIBLE_LEVELS',
                  payload: ['years', 'quarters'],
                });
              } else if (state.grid_ui.selected_level === 'quarters') {
                dispatch({
                  type: 'SET_GRIDUI_VISIBLE_LEVELS',
                  payload: ['years','quarters', 'months'],
                });
              } else {
                dispatch({
                  type: 'SET_GRIDUI_VISIBLE_LEVELS',
                  payload: ['months', 'weeks', 'days'],
                });
              }
        } else {
          if (state.grid_ui.selected_level === 'years') {
            dispatch({
              type: 'SET_GRIDUI_VISIBLE_LEVELS',
              payload: ['years', 'quarters', 'months'],
            });
          } else if (state.grid_ui.selected_level === 'quarters') {
            dispatch({
              type: 'SET_GRIDUI_VISIBLE_LEVELS',
              payload: ['years','quarters', 'months', 'weeks'],
            });
          } else if (state.grid_ui.selected_level === 'months') {
            dispatch({
              type: 'SET_GRIDUI_VISIBLE_LEVELS',
              payload: ['quarters', 'months', 'weeks','days'],
            });
          }
          else if (state.grid_ui.selected_level === 'weeks') {
            dispatch({
              type: 'SET_GRIDUI_VISIBLE_LEVELS',
              payload: ['quarters', 'months', 'weeks','days'],
            });
          }
          else if (state.grid_ui.selected_level === 'days') {
            dispatch({
              type: 'SET_GRIDUI_VISIBLE_LEVELS',
              payload: ['months', 'weeks','days'],
            });
          }
        }
      }, [isMobile, state.grid_ui.selected_level]);


    const handleCellClick = (level, visible_levels, date) => {
        //console.log(`Clicked on ${level}: ${date}. Visible levels: ${visible_levels}`);
        //console.log(date);
        const start_date = date.start;
        const end_date = date.end;
        //console.log(start_date);
        //console.log(end_date);
        dispatch({
            type: 'UPDATE_GRIDUI',
            payload: {
                start_date: start_date,
                end_date: end_date,
                visible_base_level: visible_levels[visible_levels.length - 1]
            }
        });
    }

    // Memoize the area rows
    const areaRows = useMemo(() => {
        return state.areas.map((area, index) => {
            const area_goals = state.new_goals[area.id] || [];
            return (
                <LifeAreaRow key={index} area={area} />
            )
        });
    }, [state.areas, state.new_goals]);

    if(loading) {
        return (
            <Box>Loading...</Box>
        )
    }

    return (
        <VStack width="100%">
        <CalendarBar
                position="sticky"
                top="0"
                px={0}
                zIndex={101}
                marginLeft={-8}
                marginRight={-8}
                bg={calendarBarBg}
                width={isMobile ? '100%' : "calc(100% + 32px)"}
                startDate={state.grid_ui.start_date}
                endDate={state.grid_ui.end_date}
                startingLevel={state.grid_ui.selected_level}
                onCellClick={handleCellClick} 
            />
        <VStack width="100%" spacing={0} mt={4}>
            <HStack justifyContent="flex-end" width="100%" bg={calendarBarBg} borderRadius="md" p={2} mb={2} >
                <NewGoalCreate />
            </HStack>
            
            <TimelineRow />
            <EventsRow />
            <RemindersRow />
            <TasksRow />
            {areaRows}
        </VStack>
        </VStack>
    )
}

export default NewHomeView;