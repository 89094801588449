import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Radio,
  RadioGroup,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
//import {upsertHabit} from '../../api/habitService';
import { useHabits } from '../../../data_hooks/elements/useHabits';
import NewAreaDropdown from '../../areas/components/NewAreaDropdown';
import { ForwardContext } from '../../../context/ForwardContext';
import { trackCustomEvent } from '../../../general/functions/plausibleEvent';


const HabitForm = ({
  isOpen, onClose, type, session, editorData, optionsToShow, isDisabled,
}) => {

  const toast = useToast();
  const { state, dispatch } = useContext(ForwardContext);
  const {upsertFullHabit} = useHabits();
  /*******************/
  //Form Setup and Management
  /*******************/
  //console.log(editorData.is_less_better);
  const [habitName, setHabitName] = useState(editorData.habit_name || '');
  const [habitType, setHabitType] = useState(editorData.habit_type || 'yesNo');
  const [lim, setLim] = useState(editorData.is_less_better || false);
  const [selectedArea, setSelectedArea] = useState(editorData.area_id || state.areas[0].id);


  const handleAreaChange = (value) => {
    setSelectedArea(value);
    //console.log(selectedArea);
  };

  let submit_text = '';
  let submit_mode = '';
  if(Object.keys(editorData).length === 0)
  {
    submit_text = 'Create'
    submit_mode = 'new'
  }
  else {
    submit_text = 'Update'
    submit_mode = 'edit'
  }
  const user_id = session.user.id;

  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit() {
    const habit_data = {'user_id':user_id, 'habit_name':habitName, 'habit_type':habitType, 'area_id':selectedArea, 'is_less_better':lim }
    if(editorData.id > 0) {
        habit_data["id"] = editorData.id;
    }
    console.log(habit_data);

    let {result, message} = await upsertFullHabit(habit_data);
    console.log(result);
    if(result === 'error') {
      toast.closeAll(); // Close all existing toasts
      toast({
        title: 'Error: Please upgrade your account to add more habits!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      toast.closeAll(); // Close all existing toasts
      onClose();
      toast({
        title: 'Habit Updated!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }

  };

  /*******************/
  // Return the Form
  /*******************/

  return (
    <>
    <FormControl>
    <ModalBody>
            <FormLabel fontWeight="bold">What is your habit?</FormLabel>
            <Input variant="flushed" value={habitName} onChange={(e) => setHabitName(e.target.value)} placeholder="Exercise 20 mins"/>
            <FormLabel fontWeight="bold" mt={4}>Habit Type:</FormLabel>
            <RadioGroup
              value={habitType} onChange={setHabitType}
              >
              <HStack align="start" w="100%">
                <Radio size="lg" w="50%" value="yesNo">Yes/No</Radio>
                <Radio size="lg" w="50%" value="numeric">Numeric</Radio>
              </HStack>
            </RadioGroup>
            <FormLabel fontWeight="bold" mt={4}>Is Less Better?</FormLabel>
            <Checkbox isChecked={lim} onChange={(e) => setLim(e.target.checked)}>
              Make it a Vice (e.g. snacking, drinking, etc.)
            </Checkbox>
            <FormLabel fontWeight="bold" mt={4}>Life Area:</FormLabel>
            <NewAreaDropdown mt={2} value={selectedArea} onChange={handleAreaChange} variant="flushed" />
        </ModalBody>
        <ModalFooter>
          <Button  borderRadius={0} colorScheme="forwardOrange" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            {submit_text}
          </Button>
          <Button  borderRadius={0} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
        </FormControl>
        </>
  );
};

export default HabitForm;