import React from 'react';
import { Box, Button, useColorModeValue} from '@chakra-ui/react';
import dhf from '../../../uc_utils/dates/dates.js';
import NewReminderRow from '../../reminders/rows/NewReminderRow.jsx';
import { FormContext } from '../../../context/FormContext.jsx';

const ReminderCell = ({ period, length, cell_width, itemData }) => {
    const isToday = period.start <= dhf.formatDate(new Date()) && period.end >= dhf.formatDate(new Date());
    const cellBg = useColorModeValue('gray.100', 'gray.700');

    const has_past = period.start < dhf.formatDate(new Date());
    const period_days = dhf.getDaysBetweenDates(period.start, period.end);
    const { addReminder } = React.useContext(FormContext);
    const style = period_days > 1 ? "long" : "day";
    let bgColor = 'gray.100';
    if (isToday) {
        bgColor = 'forwardGold.50';
    }
    else if (has_past) {
        bgColor = 'gray.400';
    }
    const handleAddReminder = () => {
        const editorData = {
            starting_due_date: period.start,
            cadence: 12,
        }
        addReminder(editorData);
    }

    return (
        <Box 
        borderWidth="1px 0px 1px 1px" 
        borderColor="forwardBlue.800" 
        textAlign="center" 
        fontSize="3xs" 
        minHeight="30px"
        pt={1}
        bg={bgColor}>
            {itemData.map((reminder) => {
                if (reminder.next_due_date >= period.start && reminder.next_due_date <= period.end) {
                    return (
                        <NewReminderRow key={reminder.id} reminder={reminder} style={style} />
                    )
                }
            })}
            <Button bg={cellBg} borderRadius={0} width="100%" size="xs" variant="outline" colorScheme="blue" onClick={() => handleAddReminder()}>+ Reminder</Button>
        </Box>
    )
}

export default ReminderCell;