import React, { useState } from 'react';
import c from '../../uc_constants/fwd_constants.js';

export const useCreateForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editorData, setEditorData] = useState(null);
    const [currentForm, setCurrentForm] = useState('');
    const [optionsToShow, setOptionsToShow] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
  
    const openForm = (formType, data = null, multiCreate=false, options= false) => {
      setCurrentForm(formType);
      setEditorData(data);
      setOptionsToShow(options);
      setShowOptions(multiCreate);
      setIsModalOpen(true);
    }
  
    const closeModal = () => {
      console.log('closing the modal');
      setIsModalOpen(false);
      setEditorData(null);
      setCurrentForm(null);
      setOptionsToShow(false);
      setShowOptions(false);
    }

    //Task Controls
    const addTask = (editorData = {}, show_options = false, options_to_show = c.task_options_to_show ) => {
      openForm('task', editorData, show_options, options_to_show);
    }

    const addFastTask = (editorData = {}, show_options = false, options_to_show = c.task_options_to_show ) => {
      //TODO
      console.log(editorData);
      openForm('task', editorData, show_options, options_to_show);

    }

    const addNewTask = (editorData = {}, show_options = false ) => {
      console.log(editorData);

      const task_options_to_show = {
        'name':true,
        'project':false,
        'task_list_selector':false,
        'pinned':true,
        'recur_weeks':false,
        'planned_week':true,
        'task_category':true,
    }
      openForm('task', editorData, show_options, task_options_to_show);
    
    }

    const editTask = (task, showOptions = false, optionsToShow = c.taskOptionsToShow) => {

      const task_data = {...task, modal_title: task?.modal_title || 'Edit Task'}
      openForm('task', task_data, showOptions, optionsToShow);
    };
    const editNewTask = (task, showOptions = false, optionsToShow = null) => {

      const task_data = {...task, modal_title: task?.modal_title || 'Edit Task'}
      let task_options_to_show = {
        'name':true,
        'project':false,
        'task_list_selector':false,
        'pinned':true,
        'recur_weeks':false,
        'planned_week':true,
        'task_category':true,
    }
      if(optionsToShow) {
        task_options_to_show = optionsToShow;
      }
      openForm('task', task_data, showOptions, task_options_to_show);
    }

    //Project Controls
    const addProject = (editorData = {}, show_options = false, options_to_show = c.project_options_to_show ) => {
      openForm('project', editorData, show_options, options_to_show);
    }

    const editProject = (project, showOptions = false, optionsToShow = c.projectOptionsToShow) => {
      const project_data = {...project, modal_title: 'Edit Project'}
      openForm('project', project_data, showOptions, optionsToShow);
    };

    //Habit Controls
    const addHabit = (editorData = {}, show_options = false, options_to_show = c.habit_options_to_show ) => {
      openForm('habit', editorData, show_options, options_to_show);
    }

    const editHabit = (habit, showOptions = false, optionsToShow = c.habitOptionsToShow) => {
      const habit_data = {...habit, modal_title: 'Edit Habit'}
      openForm('habit', habit_data, showOptions, optionsToShow);
    };

    //Goal Controls
    const addGoal = (editorData = {}, show_options = false, options_to_show = c.goal_options_to_show ) => {
      openForm('goal', editorData, show_options, options_to_show);
    }

    const editGoal = (goal, showOptions = false, optionsToShow = c.goalOptionsToShow) => {
      const goal_data = {...goal, modal_title: 'Edit Goal'}
      openForm('goal', goal_data, showOptions, optionsToShow);
    };

    //Reminder Controls

    const addReminder = (editorData = {}, show_options = false, options_to_show = c.reminder_options_to_show ) => {
      openForm('reminder', editorData, show_options, options_to_show);
    }

    const editReminder = (reminder, showOptions = false, optionsToShow = c.reminderOptionsToShow) => {
      const reminder_data = {...reminder, modal_title: 'Edit Reminder'}
      openForm('reminder', reminder_data, showOptions, optionsToShow);
    }

    //Area Controls
    const addArea = (editorData = {}, show_options = false, options_to_show = c.area_options_to_show ) => {
      openForm('lifeArea', editorData, show_options, options_to_show);
    }

    const editArea = (area, showOptions = false, optionsToShow = c.areaOptionsToShow) => {
      const area_data = {...area, modal_title: 'Edit Life Area'}
      openForm('lifeArea', area_data, showOptions, optionsToShow);
    }

    const addEvent = (editorData = {}, show_options = false, options_to_show = c.event_options_to_show ) => {
      openForm('event', editorData, show_options, options_to_show);
    }

    const editEvent = (event, showOptions = false, optionsToShow = c.event_options_to_show) => {
      const event_data = {...event, modal_title: 'Edit Event'}
      openForm('event', event_data, showOptions, optionsToShow);
    }

    const addNewGoal = (editorData = {}, show_options = false, options_to_show = c.new_goal_options_to_show ) => {
      openForm('new_goal', editorData, show_options, options_to_show);
    }

    const editNewGoal = (goal, showOptions = false, optionsToShow = c.new_goal_options_to_show) => {
      const goal_data = {...goal, modal_title: 'Edit Goal'}
      openForm('new_goal', goal_data, showOptions, optionsToShow);
    }
  
    return {
      isModalOpen,
      openForm,
      closeModal,
      currentForm,
      editorData,
      optionsToShow,
      showOptions,
      addTask,
      addFastTask,
      addNewTask,
      editTask,
      editNewTask,
      addProject,
      editProject,
      addHabit,
      editHabit,
      addGoal,
      editGoal,
      addReminder,
      editReminder,
      addArea,
      editArea,
      addEvent,
      editEvent,
      addNewGoal,
      editNewGoal,
    };
  };
  