
import React from 'react';
import { Box, VStack, Text, Popover, PopoverTrigger, Button, useDisclosure, Tooltip, Flex, useColorModeValue} from '@chakra-ui/react';
import NewTaskRow from '../rows/NewTaskRow';
import { Droppable } from '@hello-pangea/dnd';
import { FormContext } from '../../../context/FormContext';
import dhf from '../../../uc_utils/dates/dates.js'
import FastTaskForm from '../../tasks/forms/FastTaskForm.jsx';

const TaskPeriodContainerBox = ({ tasks, date, task_category, task_list,  ...props }) => {
    const cellbg=useColorModeValue('gray.100','gray.700');
    const { addTask, addFastTask } = React.useContext(FormContext);
    const backgroundColor = useColorModeValue("forwardWhite.200", "forwardBlue.800");
    const hoverColor = useColorModeValue("forwardOrange.300", "forwardBlue.900");

    let cat_to_set = 1;
    let pin_to_set = false;
    let editor_bucket = 'planned'

    let dateToShow = date;
    if(date === null) {
        dateToShow = 'null-null-null';
    }


    const handleAddTask = (mode="standard") => {
        let ipw = null;
        let shown_list = task_list;
        let options_to_show = {
            'name':true,
        'project':false,
        'task_list_selector':false,
        'pinned':true,
        'recur_weeks':false,
        'planned_week':true,
        'task_category':true,
        }
        if(mode === 'fast') {
            options_to_show.task_category = false;
            options_to_show.planned_week = false;
        }
        if(shown_list === 'pinned') {
            cat_to_set = 1;
            pin_to_set = true;
            ipw = dhf.formatDate(date);
        }
        else if (shown_list === 'backlog') {
            editor_bucket = 'backlog';
            options_to_show['task_list_selector'] = true;
            options_to_show['planned_week'] = false;
            task_list = 'backlog';
            options_to_show['planned_week'] = false;
            options_to_show['task_list_selector'] = true;
            cat_to_set = task_category.id || 1;
        }
        else if (shown_list === 'priority') {
            editor_bucket = 'priority';
            options_to_show['task_list_selector'] = true;
            options_to_show['planned_week'] = false;
            task_list = 'priority';
            options_to_show['planned_week'] = false;
            options_to_show['task_list_selector'] = true;
            cat_to_set = task_category.id || 1;
        }
        else if (shown_list === 'toschedule') {
            editor_bucket = 'toschedule';
            options_to_show['task_list_selector'] = true;
            options_to_show['planned_week'] = false;
            task_list = 'toschedule';
            options_to_show['planned_week'] = false;
            options_to_show['task_list_selector'] = true;
            cat_to_set = task_category.id || 1;
        }
        else {
            shown_list = 'planned';
            ipw = dhf.formatDate(date);
            cat_to_set = task_category.id;
        }
        const editorData = {
            'item_planned_week': ipw,
            'task_category': cat_to_set,
            'is_pinned': pin_to_set,
            'item_status': editor_bucket,
            'taskList': shown_list,
        }

        console.log(editorData);

        if(mode === 'standard') {
        addTask(editorData,false, options_to_show);
        }
        else if (mode === 'fast') {
            addFastTask(editorData,false, options_to_show);
        }
    }

    return (
        <>
        <Droppable key={'tc-newhome-date-'+dateToShow+'-list-'+task_list+'-cat-'+task_category.id} droppableId={'tc-newhome-date-'+dateToShow+'-list-'+task_list+'-cat-'+task_category.id} width="100%">
          {(provided, snapshot) => (
            <Flex 
            flexDirection = "column"
            width="100%"
            minHeight="100px"
              {...provided.droppableProps}
              ref={provided.innerRef}
              backgroundColor={snapshot.isDraggingOver ? hoverColor : backgroundColor}
              {...props}
            >
                <Text fontSize="2xs" fontStyle="oblique">{task_category.id > 0 ? task_category.value : task_list.charAt(0).toUpperCase() + task_list.slice(1)}</Text>
    {tasks?.sort((a, b) => {
            if (a.is_pinned === b.is_pinned) {
              return a.item_name.localeCompare(b.item_name);
            }
            return a.is_pinned ? -1 : 1;
          })?.map((entry, index) => {

    let taskList = null;
    if (['backlog','priority','toschedule','accomplishments'].includes(task_list)) {
        taskList = task_list;
    }
    else {
        taskList = 'all_tasks'
    }

    return (
        <NewTaskRow key={entry.id} bg={cellbg} width="100%" task={entry} index={index} taskList={taskList} />
    );
    })}
    {provided.placeholder}
    </Flex>
          )}
        </Droppable>
    {date === null ? 
    <Button bg={cellbg} size="xs" borderRadius={0} fontSize="sm" colorScheme="blue" variant="outline" width="100%" onClick={() => handleAddTask('standard')}>+ Task</Button> :
    <Button bg={cellbg}  size="xs" borderRadius={0} fontSize="sm" colorScheme="blue" variant="outline" width="100%" onClick={() => handleAddTask('fast')}>+ Task</Button>}
    </>
    )
}

export default TaskPeriodContainerBox;