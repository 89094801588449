import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useAreas } from '../../../data_hooks/elements/useAreas';
import { ForwardContext } from '../../../context/ForwardContext';


const LifeAreaForm = ({
  isOpen, onClose, session, editorData, optionsToShow, isDisabled,
}) => {

  const {upsertFullArea} = useAreas();
  const toast = useToast();
  //console.log(editorData)

  /*******************/
  //Form Setup and Management
  /*******************/
  const { state, dispatch } = useContext(ForwardContext);
  const [newName, setNewName] = useState(editorData.area_name || '');
  //console.log(editorData);
  let submit_text = '';
  let submit_mode = '';
  if(Object.keys(editorData).length === 0)
  {
    submit_text = 'Create'
    submit_mode = 'new'
  }
  else {
    submit_text = 'Update'
    submit_mode = 'edit'
  }
  const user_id = session.user.id;

  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit() {

    const area_data = {'user_id':user_id, 'area_name':newName}
    if(editorData.id > 0) {
      area_data["id"] = editorData.id;
    }
    console.log(area_data);
    let {result, message} = await upsertFullArea(user_id, area_data);
    //Submit to Database
    //const new_goal = await upsertGoal(goal_data);
    //trackCustomEvent('FWD Goal Added', {goal_name: newName});
    console.log(result);
    if(result === 'limit') {
      toast.closeAll(); // Close all existing toasts
      toast({
        title: 'Error: Please upgrade your account to add more areas!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else if (result === 'project_limit') {
      toast.closeAll(); // Close all existing toasts
      toast({
        title: 'Your Area was created, but you have reached your project limit so no project exists for this life area.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }

    else if (result === 'error' || result === 'project_error') {
      toast.closeAll(); // Close all existing toasts
      toast({
        title: 'Error: '+message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      onClose();
      toast.closeAll(); // Close all existing toasts
      toast({
        title: 'Area Updated!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }

  };

  /*******************/
  // Return the Form
  /*******************/

  return (
    <>
    <FormControl>
        <ModalBody>
            <FormLabel fontWeight="bold">Life Area:</FormLabel>
            <Input
              value={newName}
              variant="flushed"
              placeholder="Finances"
              onChange={(e) => setNewName(e.target.value)}
            />
        </ModalBody>

        <ModalFooter>
          <Button borderRadius="0px" colorScheme="forwardOrange" mr={3} onClick={handleSubmit} isDisabled={isDisabled}>
            {submit_text}
          </Button>
          <Button borderRadius="0px" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
        </FormControl>
        </>
  );
};

export default LifeAreaForm;