import React, { useState, useContext, useRef, useEffect } from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Button,
    Input,
    FormControl,
    useToast,
  } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext.jsx';
import { trackCustomEvent } from '../../../general/functions/plausibleEvent.js';
import { useTasks } from '../../../data_hooks/elements/useTasks.jsx';
import th from '../../../uc_utils/tasks/tasks.js';

const FastTaskForm = ({ project_id, label, button_style="standard", ...props
}) => {

  /*******************/
  //Form Setup and Management
  /*******************/
  const { state, dispatch } = useContext(ForwardContext);
  const {upsertFullTask} = useTasks();
  const toast = useToast();
  const inputRef = useRef();
  const [itemText, setItemText] = useState('');
  const is_readOnly = (state.ui.readOnly);

  const handlePopoverOpen = () => {
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0.1); // or increase the delay a bit if required.

    return () => clearTimeout(timeoutId); // Cleanup function to clear the timeout
  }

  let display_name = '+ Task';
  let element = 'Task';
  let item_status = 'planned';
  let complete_date = null;
  let task_list = 'current_tasks';
  if (label === 'accomplishments') {
    display_name = '+ Achievement';
    element = 'Achievement';
    item_status = 'complete';
    complete_date = new Date();
    task_list = 'accomplishments';
  }
  else if (label === 'backlog') {
    display_name = '+ Backlog';
    element = 'Task';
    item_status = 'backlog';
    task_list = 'backlog'
  }
  else if (label === 'priority') {
    display_name = '+ Priority';
    element = 'Task';
    item_status = 'priority';
    task_list = 'priority'
  }
  else if (label === 'next_week') {
    display_name = '+ Next Week';
    element = 'Task';
    item_status = 'planned';
    task_list = 'next_week';
  }
  else if (label === 'this_week') {
    display_name = '+ This Week';
    element = 'Task';
    item_status = 'planned';
    task_list = 'this_week';
  }


  /*******************/
  //Submit to Database
  /*******************/
  async function handleSubmit(e) {
    e.preventDefault();


    const task_planned_week = th.calculateTaskPlannedWeek(label, state.ui.currentWeek, state.ui.thisWeek, state.ui.nextWeek);
    trackCustomEvent('FWD Task Added', {'add_method':'fast'});
    const task_data = {'item_name':itemText, 
                       'project_id':project_id, 
                       'item_status':item_status, 
                       'item_planned_week':task_planned_week,
                       'original_plan_date':task_planned_week,
                       'resolve_date':complete_date}
    
    console.log(task_data);
    //Submit to Database
    
    let {result, message} = await upsertFullTask(task_data,'fast',task_list, [], null);

    //Provide Feedback to user based on the outcome
    if(result === 'limit') {
      toast.closeAll(); // Close all existing toasts
      toast({
        title: 'Error: Task limit reached! Complete a few or upgrade your account!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else if (result === 'error') {
      toast.closeAll(); // Close all existing toasts
      toast({
        title: 'Error: '+message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    else {
      //onClose();
      setItemText('');
      toast.closeAll(); // Close all existing toasts
      toast({
        title: 'New Task Added!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  /*******************/
  // Return the Form
  /*******************/
  let button_element = <Button  borderRadius={0}  
  fontSize="xs" 
  maxHeight="18px" 
  m={2} 
  px={2} 
  py={0} 
  backgroundColor="forwardGold.100" 
  textColor="black" {...props}>{display_name}</Button>;
  
  if (button_style ==='project_header') {
    button_element = <Button
    colorScheme="forwardBlue"
    variant="ghost"
    borderRadius="0"
    p={1}
    size="xs"
    fontSize="xs">
      + Task
      </Button>


  }

  return (
    <Popover onOpen={handlePopoverOpen} trapFocus={false}>
      <PopoverTrigger>
        {button_element}
      </PopoverTrigger>
      <PopoverContent  borderRadius={0}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="bold" fontSize="sm">Add a {element}</PopoverHeader>
        <PopoverBody>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <Input
                ref={inputRef}
                value={itemText}
                variant="flushed"
                onChange={(e) => setItemText(e.target.value)}
                isDisabled={is_readOnly}
                placeholder={`Enter a ${element}`}
              />
              <Button  borderRadius={0} colorScheme="forwardOrange" my={1} type="submit" width="100%" isDisabled={is_readOnly}>Submit</Button>
            </FormControl>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FastTaskForm;