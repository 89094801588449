import React from 'react';
import { Box, Flex, Text, VStack, Tooltip, HStack, useMediaQuery, useColorModeValue } from '@chakra-ui/react';
import { ForwardContext } from '../../../context/ForwardContext';
import { generateCells } from '../helpers/cells';
import dhf from '../../../uc_utils/dates/dates.js';
import ReminderCell from '../cells/ReminderCell.jsx';

const RemindersRow = ({ ...props }) => {
    const { state } = React.useContext(ForwardContext);
    const [isMobile] = useMediaQuery("(max-width: 600px)");
    const rowBg = useColorModeValue("forwardWhite.100", "forwardBlue.700");
    const textColor = useColorModeValue("gray.800", "gray.200");
    
    //Reminders are grouped by life area - we need to flatten that into a single array for my_reminders
    const my_reminders = Object.values(state?.reminders || {}).flat();
    //console.log(my_reminders);

    if(!state.grid_ui.start_date || !state.grid_ui.end_date) return null;


    const timelineCells = generateCells(
                            state.grid_ui.start_date, 
                            state.grid_ui.end_date, 
                            state.grid_ui.selected_level === 'weeks' ? 'weeks' : dhf.getEventDisplayLevel(state.grid_ui.selected_level), 
                            ReminderCell, 
                            my_reminders);


    return (
        <HStack width="100%" bg={rowBg} borderRadius="md" py={0} mb={2} >
            {!isMobile && <Box flex="2" minWidth="200px" textAlign="left" fontSize="2xs" ml={2} color={textColor}>Reminders</Box>}
            <HStack alignItems="stretch" flex="20" spacing={0} width="100%" justifyContent="stretch" borderRight="1px solid" borderColor="forwardBLue.800">
            {timelineCells}
            </HStack>
        </HStack>
    )
}


export default RemindersRow;